import * as React from 'react';
import { getApi, postApi, putApi } from '../api/api';
import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ApiSpace, SpaceState, GenerationResult, ApiEnvironment } from '../api/ApiTypes';
import useInterval from '../hooks/useInterval';
import {
    Engine,
    Scene,
    CubeTexture,
    ThinEngine,
    AbstractMesh,
    EnvironmentTextureTools,
    EquiRectangularCubeTexture,
    HDRFiltering,
    ArcRotateCamera,
    Vector3,
    Color4,
    Animation,
    SceneLoader,
    Camera,
    TransformNode,
    PointerEventTypes,
    PhotoDome,
    Mesh,
    Nullable,
    Tools
} from '@babylonjs/core';
import Spinner from '../images/i-generate-spinner-64px-green.svg';
import {} from '@babylonjs/loaders';
import styled from 'styled-components';
import IconClose from '../images/i-close.svg';
import IconPro from '../images/atom-i-action.svg';
import IconSkybox from '../images/atom-i-skybox.svg';
import IconCopy from '../images/atom-i-link.svg';
import IconCopyHover from '../images/atom-i-link-green.svg';
import IconSettings from '../images/atom-i-settings.svg';
import MeetDropdown from '../components/header/MeetDropdown';
import { canCreateStation } from '../permissions/users';
import { useFirebase } from 'react-redux-firebase';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from '../store/selectors';
import { clearCurrentUser, setCurrentUser } from '../store/actions';
import { RootState } from '../store/reducers';
import GoogleAuthButton from '../components/inputs/GoogleAuthButton';
import GoogleAuthButtonDummy from '../components/inputs/GoogleAuthButtonDummy';
import { setTimeout } from 'timers';
import GoogleAuthButtonMeet from '../components/inputs/GoogleAuthButtonMeet';
import { isCrossDomainCookieAccessEnabled } from '../utils/CookieUtil';
import { isAnon } from '../permissions/users';
import MeetGoPro from '../components/header/MeetGoPro';
import { canViewAccountUpsell } from '../permissions/users';
import { SubscriptionModal } from '../components/modals/PaymentModals';

const CorsAnywhereEnabled = false;
let CORS_ANYWHERE = '';
if (CorsAnywhereEnabled) {
    CORS_ANYWHERE = 'https://cors-anywhere.urnowhere.com/';
}

// default skybox generation style array in case the api fails
const skyboxStyleArray = [
    { id: 5, name: 'Digital Painting', 'max-char': '420', image: null, sort_order: 1 },
    { id: 9, name: 'Realistic', 'max-char': '360', image: null, sort_order: 2 },
    {
        id: 3,
        name: 'Anime Art Style',
        'max-char': '425',
        image: 'https://blockade-platform-production.s3.amazonaws.…ybox/Ki2I4oxMHoZRiKzCCGJD7TbTjgQ69N7QICTzb2zx.png',
        sort_order: 3
    },
    {
        id: 2,
        name: 'Fantasy Lands',
        'max-char': '350',
        image: 'https://blockade-platform-production.s3.amazonaws.…ybox/imG0GHHOlErLJzWE90hxK5cpmyoDofV0AVtDXAHa.png',
        sort_order: 4
    },
    { id: 13, name: 'Advanced (no style)', 'max-char': '540', image: null, sort_order: 5 },
    { id: 10, name: 'SciFi', 'max-char': '360', image: null, sort_order: 6 },
    { id: 20, name: 'Modern Computer Animation', 'max-char': '480', image: null, sort_order: 7 },
    { id: 6, name: 'Scenic', 'max-char': '420', image: null, sort_order: 8 },
    { id: 11, name: 'Dreamlike', 'max-char': '400', image: null, sort_order: 9 },
    { id: 17, name: 'Oil Painting', 'max-char': '320', image: null, sort_order: 10 },
    { id: 25, name: 'Manga (Testing)', 'max-char': '420', image: null, sort_order: 11 },
    { id: 16, name: 'Sky', 'max-char': '350', image: null, sort_order: 12 },
    { id: 15, name: 'Interior Views', 'max-char': '400', image: null, sort_order: 13 },
    { id: 4, name: 'Surreal Style', 'max-char': '340', image: null, sort_order: 14 },
    { id: 22, name: 'Watercolor (testing)', 'max-char': '375', image: null, sort_order: 15 },
    { id: 7, name: 'Nebula', 'max-char': '420', image: null, sort_order: 16 },
    { id: 24, name: 'Pen & Ink (testing)', 'max-char': '300', image: null, sort_order: 17 },
    { id: 23, name: 'Technical (testing)', 'max-char': '300', image: null, sort_order: 18 },
    { id: 19, name: 'Low Poly (Experimental)', 'max-char': '460', image: null, sort_order: 19 },
    { id: 26, name: 'Interior Archviz (testing)', 'max-char': '370', image: null, sort_order: 20 },
    {
        id: 18,
        name: 'Infrared (experimental nature scenes)',
        'max-char': '420',
        image: 'https://blockade-platform-production.s3.amazonaws.…ybox/cMcfRvpPVKz3cZM5cWbKYICcyxvfPTeKzn5Yn0hY.jpg',
        sort_order: 21
    },
    { id: 28, name: 'testing', 'max-char': '260', image: null, sort_order: 22 }
];

const FOREST_ENV_ID_NAME = 'forest_meet';
const MINI_ENV_ID_NAME = 'mini-meet';
const ROOFTOP_ENV_NAME = 'rooftop';

const MINI_MEET_CAMERA_POS = new Vector3(-7, 2.5, 0);
const MINI_MEET_CAMERA_TARGET = new Vector3(0, 0, 0);

const ROOFTOP_CAMERA_POS = new Vector3(0, 0, -7);
const ROOFTOP_CAMERA_TARGET = new Vector3(0, 0, 0);

const FORREST_CAMERA_POS = new Vector3(5, 2, 5);
const FORREST_CAMERA_TARGET = new Vector3(0, 0, 0);

const SCENE_DEBUG = false;
const MODEL_SPIN_SPEED = 5000;
//// Version 2 /////
export class SkyBox {
    name: string;
    url: string | undefined;
    isDefault: boolean;
    isActive: boolean;
    filetype: string;
    isJpgToEnv?: boolean;
    jpgUrl?: string;
    urlMediumRes?: string;
    urlHighRes?: string;

    constructor(
        name: string,
        url: string | undefined,
        isDefault: boolean,
        isActive: boolean,
        filetype: string,
        isJpgToEnv?: boolean,
        jpgUrl?: string,
        urlMediumRes?: string,
        urlHighRes?: string
    ) {
        this.name = name;
        this.url = url;
        this.isDefault = isDefault;
        this.isActive = isActive;
        this.filetype = filetype;
        this.isJpgToEnv = isJpgToEnv;
        this.jpgUrl = jpgUrl;
        this.urlMediumRes = urlMediumRes;
        this.urlHighRes = urlHighRes;
    }
}

export class DefaultSkyBox {
    name: string;
    url: string | undefined;
    isDefault: boolean;
    isActive: boolean;
    filetype: string;
    isJpgToEnv?: boolean;
    envName?: string;
    jpgUrl?: string;
    constructor(
        name: string,
        url: string | undefined,
        isDefault: boolean,
        isActive: boolean,
        filetype: string,
        isJpgToEnv?: boolean,
        envName?: string,
        jpgUrl?: string
    ) {
        this.name = name;
        this.url = url;
        this.isDefault = isDefault;
        this.isActive = isActive;
        this.filetype = filetype;
        this.isJpgToEnv = isJpgToEnv;
        this.envName = envName;
        this.jpgUrl = jpgUrl;
    }
}

const envExportImageTypes = [
    { label: 'PNG', value: 0, imageType: 'image/png' },
    { label: 'WebP', value: 1, imageType: 'image/webp' }
];

interface SceneComponentProps {
    antialias?: boolean;
    engineOptions?: any;
    adaptToDeviceRatio?: boolean;
    sceneOptions?: any;
    onRender?: (scene: Scene) => void;
    onSceneReady?: (scene: Scene) => void;
}

const Meet: React.FC<SceneComponentProps> = ({
    antialias,
    engineOptions,
    adaptToDeviceRatio,
    sceneOptions,
    onRender,
    onSceneReady,
    ...rest
}) => {
    const history = useHistory();

    const { spaceId, stationId } = useParams() as any;

    const [camera, setCamera] = useState({} as ArcRotateCamera);

    const host = new URLSearchParams(document.location.search).get('host');

    const [defaultMeetEnvironments, setDefaultMeetEnvironments] = useState([] as { [key: string]: any }[]);
    const [skyboxGenTypes, setSkyboxGenTypes] = useState([] as { [key: string]: any }[]);
    const [space, setSpace] = useState({} as ApiSpace);
    const [spaceState, setSpaceState] = useState({} as { [key: string]: any });
    const [sbGenResults, setSbGenResults] = useState({} as GenerationResult | null);
    const [worldName, setWorldName] = useState('Meet Space' as string);
    const [environmentId, setEnvironmentId] = useState('' as string);
    const [environmentName, setEnvironmentName] = useState('' as string);
    const [inviteLink, setinviteLink] = useState('' as string);
    const [skyBoxArray, setSkyBoxArray] = useState([] as { [key: string]: any }[]);
    const [defaultSkyBoxArray, setDefaultSkyBoxArray] = useState([] as { [key: string]: any }[]);
    const [prompt, setPrompt] = useState('' as string);
    const [SPACE, STATION, DEFAULT] = ['SPACE', 'STATION', 'DEFAULT'];
    const [loading, setLoading] = useState(true);
    const [preLoading, setPreLoading] = useState(true);
    const [loadingGen, setLoadingGen] = useState(false);
    const [preLoadingGen, setPreLoadingGen] = useState(false);
    const [loadingEnv, setLoadingEnv] = useState(false);
    const [preLoadingEnv, setPreLoadingEnv] = useState(false);
    const [loadingSkybox, setLoadingSkybox] = useState(false);
    const [preLoadingSkybox, setPreLoadingSkybox] = useState(false);

    const [scene, setScene] = useState({} as Scene);
    const [skyboxUrl, setSkyboxUrl] = useState('' as string | undefined);
    const [skyboxGenType, setSkyboxGenType] = useState([{ name: 'Digital Painting', id: 5, maxChar: 420 }] as {
        [key: string]: any;
    });
    const [copied, setCopied] = useState(false);
    const [generatingSkybox, setGeneratingSkybox] = useState(false);

    const [skyboxProgressString, setSkyboxProgressString] = useState('' as string);
    const [changingEnvironment, setChangingEnvironment] = useState(false);

    const [meetInviteCode, setMeetInviteCode] = useState('' as string);
    const [environmentIdIndex, setEnvironmentIdIndex] = useState(0);
    const [defaultMeetEnvs, setDefaultMeetEnvs] = useState([] as ApiEnvironment[]);
    const [inviteCopiedText, setInviteCopiedText] = useState('Invite' as string);
    const [miniMeet, setMiniMeet] = useState({} as AbstractMesh);
    const [forrestModel, setForrestModel] = useState({} as AbstractMesh);
    const [rooftopModel, setRooftopModel] = useState({} as AbstractMesh);

    const [photoDome, setPhotoDome] = useState({} as PhotoDome);

    const [parentNode, setParentNode] = useState({} as TransformNode);
    const [parentNodeLimited, setParentNodeLimited] = useState({} as TransformNode);

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showLoginSmallBox, setShowLoginSmallBox] = useState(false);
    const [showUpgradeSmallBox, setShowUpgradeSmallBox] = useState(false);
    const [loginPromptVisible, setLoginPromptVisible] = useState(false);

    const [upgradePromptVisible, setUpgradePromptVisible] = useState(false);
    const [hasHostedSpaces, setHasHostedSpaces] = useState(false);
    const currentUser = useSelector(getCurrentUser);
    const [isStationManager, setIsStationManager] = useState(false);
    const [isSettingsRowOpened, setIsSettingsRowOpened] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [showErrorText, setShowErrorText] = useState(false);
    const [showSkyboxLoadingError, setShowSkyboxLoadingError] = useState(false);

    const isFramed = () => {
        try {
            const tl = window.top!.location.href; //this error if we are in a frame
            return false;
        } catch (e) {
            return true;
        }
    };

    useEffect(() => {
        //we need to bust out of the iFrame if cross domain cookies are not available
        if (isFramed() && !isCrossDomainCookieAccessEnabled()) window.top!.location.href = document.location.href;

        //use the user/me route to see if we have a new userType
        getApi(`/user/me`)
            .then(async (r) => {
                const response = await r.json();
                console.log('`/user/me`', response);
                if (r.ok) {
                    dispatch(setCurrentUser(response));
                }
                //check url params for one called login
                const urlParams = new URLSearchParams(window.location.search);
                console.log('Url Params: ', urlParams.get('login'));
                const showLoginParam = urlParams.get('login');

                if (showLoginParam === 'true' && isAnon(response)) {
                    setLoginPromptVisible(true);
                }
            })
            .then(() => /* history.push(redirectRoute) */ null)
            .catch((e: Error) => {
                console.error('Could not get updated user with error: ', e);
                //  history.push(redirectRoute);
            });
    }, []);

    useEffect(() => {
        if (meetInviteCode) {
            const iLinkHost: any = host ? `${host}` : `${window.location.protocol}//${window.location.host}`;
            const iLink = host ? `?invite=${meetInviteCode}` : `/meetInvite/${meetInviteCode}`;
            setinviteLink(`${iLinkHost}${iLink}`);
        }
    }, [meetInviteCode]);

    const checkIsMobile = () => {
        let check = false;
        (function (a) {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                    a
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(
                    a.substr(0, 4)
                )
            )
                check = true;
        })(navigator.userAgent || navigator.vendor);
        console.log('isMobile?', check);
        setIsMobile(check);
    };

    const checkIsIOS = () => {
        return (
            /iPad|iPhone|iPod|iPhone Simulator|iPad Simulator|iPad Simulator/.test(navigator.platform) ||
            (navigator.maxTouchPoints &&
                navigator.maxTouchPoints > 2 &&
                /MacIntel|Macintosh/.test(navigator.platform)) ||
            (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
        );
    };

    const spaceStationOrDefault = spaceId ? SPACE : stationId ? STATION : DEFAULT;
    // Create a ref to the canvas element
    const reactCanvas = React.useRef(null);

    const _envOptions = { imageTypeIndex: 1, imageQuality: 0.8 };

    const getDefaultMeetEnvs = async () => {
        return await getApi('/environments/meet').then(async (r) => {
            const json = await r.json();
            if (r.ok) {
                setDefaultMeetEnvs(json);
                return json;
            }
            return defaultMeetEnvs;
        });
    };

    const requestMeet = async (meetApiUri: string): Promise<{ [key: string]: any }> => {
        let maxAttempts = 5;
        let ok = false;
        let r: { [key: string]: any } = {};
        while (!ok && maxAttempts > 0) {
            r = await new Promise(async (resolve) => {
                setTimeout(async () => {
                    return resolve(await getApi(meetApiUri));
                }, 300);
            });
            //r = await getApi(meetApiUri);
            ok = r.ok;
            maxAttempts -= 1;
        }
        return r;
    };
    // on load, hit the meet api and begin the loading sequence
    useEffect(() => {
        //call check is mobile right away so we can load the proper ui
        checkIsMobile();
        console.log('isIos: ', checkIsIOS());
        if (checkIsIOS() == true) {
            console.log('isIos == true, setting to mobile mode');
            setIsMobile(true);
        }
        let localDefaultMeetEnvironmentsForLoading: any = null;
        const meetApiUri = (() => {
            switch (spaceStationOrDefault) {
                case SPACE:
                    return `/meet/space/${spaceId}`;
                    break;
                case STATION:
                    return `/meet/station/${stationId}`;
                    break;
                default:
                    return `/meet`;
            }
        })();

        requestMeet(meetApiUri)
            .then(async (r) => {
                const json = await r.json();
                if (!r.ok) {
                    console.log(json.message || 'Could not get space data');
                }
                // set default values for scene and react that come from the space state
                if (json.space) {
                    console.log('Json on Load: ', json);
                    if (json.skyboxTypes) {
                        setSkyboxGenTypes(json.skyboxTypes);
                        if (json.skyboxTypes[0].id) {
                            setSkyboxGenType({
                                name: json.skyboxTypes[0].name,
                                id: json.skyboxTypes[0].id,
                                'max-char': json.skyboxTypes[0]['max-char']
                            });
                        }
                    } else {
                        setSkyboxGenTypes(skyboxStyleArray);
                    }
                    setSpaceState(json.state);
                    setSpace(json.space);
                    setWorldName(json.space.title);
                    setEnvironmentId(json.space.environmentId);
                    setEnvironmentName(json.space.environment.displayName);
                    setMeetInviteCode(json.meetInviteCode);

                    getDefaultMeetEnvs()
                        .catch((e: Error) => {
                            console.log('Could not get default meets with error: ', e);
                        })
                        .then((res) => {
                            console.log('Default Envs: ', res);

                            const defaultMeetSpaceIds = res.map((env: any) => ({
                                environmentId: env.id,
                                environmentName: env.displayName,
                                idName: env.idName
                            }));
                            localDefaultMeetEnvironmentsForLoading = defaultMeetSpaceIds;

                            const matchingDefaultMeetEnv = localDefaultMeetEnvironmentsForLoading.find((envId: any) => {
                                if (envId.environmentId == json.space.environmentId) {
                                    return envId;
                                }
                            });
                            console.log(
                                'Local Default Meet Envs',
                                localDefaultMeetEnvironmentsForLoading,
                                'json.space.environmentId',
                                json.space.environmentId,
                                'matchingDefaultMeetEnv',
                                matchingDefaultMeetEnv
                            );

                            if (matchingDefaultMeetEnv == undefined) {
                                console.log('Adding non default meet space to list');
                                const newDefaultMeetSpace = {
                                    environmentId: json.space.environmentId,
                                    environmentName: json.space.environment.displayName,
                                    idName: json.space.environment.idName
                                };
                                localDefaultMeetEnvironmentsForLoading.push(newDefaultMeetSpace);
                                setDefaultMeetEnvironments(localDefaultMeetEnvironmentsForLoading); // update the state with the new array
                            } else {
                                setDefaultMeetEnvironments(defaultMeetSpaceIds);

                                // begin loading of 3d scene. Create parent nodes and animations
                                const parentNode = new TransformNode('modelParent', scene);

                                //360 animation
                                const animation = new Animation(
                                    'modelRotationAnimation',
                                    'rotation.y',
                                    60,
                                    Animation.ANIMATIONTYPE_FLOAT,
                                    Animation.ANIMATIONLOOPMODE_CYCLE
                                );
                                const keys = [
                                    {
                                        frame: 0,
                                        value: 0
                                    },
                                    {
                                        frame: MODEL_SPIN_SPEED,
                                        value: Math.PI * 2
                                    }
                                ];
                                animation.setKeys(keys);
                                if (!parentNode) return;
                                console.log('Adding animation to parent node');
                                parentNode.animations.push(animation);
                                scene.beginAnimation(parentNode, 0, MODEL_SPIN_SPEED, true);
                                setParentNode(parentNode);
                                scene.clearColor = new Color4(0.004, 0, 0.153, 1.0);

                                //create the camera for the scene
                                const camera = new ArcRotateCamera(
                                    'camera',
                                    0 - Math.PI / 2,
                                    Math.PI / 2.5,
                                    15,
                                    new Vector3(0, 0, 0)
                                );
                                camera.attachControl();
                                camera.fov = 1.5;
                                camera.position = new Vector3(300, 150, 0);
                                camera.setTarget(new Vector3(0, 50, 0));
                                setCamera(camera);

                                // Set up the render loop for the engine
                                engine.runRenderLoop(() => {
                                    // Call the onRender function if it is defined
                                    if (typeof onRender === 'function') onRender(scene);
                                    // Render the scene
                                    scene.render();
                                });
                                const matchingDefaultMeetEnv = localDefaultMeetEnvironmentsForLoading.find(
                                    (envId: any) => {
                                        if (envId.environmentId == json.space.environmentId) {
                                            return envId;
                                        }
                                    }
                                );
                                console.log();
                                //    Load in the models for the 3 default environments
                                //    We set the initial camera poisiton and aim position as well as the visibility of each model here too

                                SceneLoader.ImportMesh(
                                    '',
                                    'https://storage.googleapis.com/assets.urnowhere.com/publicmedia/meetPreviewWorlds/',
                                    'rooftop_Mini_Model_pods.glb',
                                    scene,
                                    (meshes) => {
                                        const rooftopModelLocal = meshes[0];
                                        setRooftopModel(rooftopModelLocal);
                                        rooftopModelLocal.parent = parentNode;
                                        rooftopModelLocal.translate(new Vector3(1, 0, 0), -13);
                                        /*             parentNode.parent = parentNodeLimited; */
                                        rooftopModelLocal.doNotSyncBoundingInfo = true;
                                        rooftopModelLocal.position = new Vector3(13, 2, -4);
                                        if (matchingDefaultMeetEnv) {
                                            if (matchingDefaultMeetEnv.idName == ROOFTOP_ENV_NAME) {
                                                console.log('This is a rooftop');
                                                rooftopModelLocal.setEnabled(true);
                                                camera.setTarget(ROOFTOP_CAMERA_TARGET);
                                                camera.position = ROOFTOP_CAMERA_POS;
                                            } else {
                                                rooftopModelLocal.setEnabled(false);
                                            }
                                        }
                                    },
                                    undefined,
                                    undefined
                                );

                                SceneLoader.ImportMesh(
                                    '',
                                    'https://storage.googleapis.com/assets.urnowhere.com/publicmedia/meetPreviewWorlds/',
                                    'miniMeetPreviewPods.glb',
                                    scene,
                                    (meshes) => {
                                        const miniMeetModelLocal = meshes[0];
                                        setMiniMeet(miniMeetModelLocal);
                                        miniMeetModelLocal.parent = parentNode;
                                        miniMeetModelLocal.doNotSyncBoundingInfo = true;
                                        miniMeetModelLocal.position = new Vector3(0.5, 0, -3.5);
                                        if (matchingDefaultMeetEnv) {
                                            if (matchingDefaultMeetEnv.idName == MINI_ENV_ID_NAME) {
                                                console.log('This is mini meet');
                                                miniMeetModelLocal.setEnabled(true);
                                                camera.setTarget(MINI_MEET_CAMERA_TARGET);
                                                camera.position = MINI_MEET_CAMERA_POS;
                                            } else {
                                                miniMeetModelLocal.setEnabled(false);
                                            }
                                        }
                                    },
                                    undefined,
                                    undefined
                                );

                                SceneLoader.ImportMesh(
                                    '',
                                    'https://storage.googleapis.com/assets.urnowhere.com/publicmedia/meetPreviewWorlds/',
                                    'simpleForrestPods.glb',
                                    scene,
                                    (meshes) => {
                                        const forrestModelLocal = meshes[0];
                                        forrestModelLocal.isPickable = false;
                                        forrestModelLocal.doNotSyncBoundingInfo = true;
                                        setForrestModel(forrestModelLocal);
                                        forrestModelLocal.parent = parentNode;
                                        forrestModelLocal.position = new Vector3(-3.5, 0, -19.5);
                                        if (matchingDefaultMeetEnv) {
                                            if (matchingDefaultMeetEnv.idName == FOREST_ENV_ID_NAME) {
                                                console.log('This is forrest');
                                                forrestModelLocal.setEnabled(true);
                                                camera.position = FORREST_CAMERA_POS;
                                                camera.setTarget(FORREST_CAMERA_TARGET);
                                            } else {
                                                forrestModelLocal.setEnabled(false);
                                            }
                                        }
                                    },
                                    undefined,
                                    undefined
                                );
                            }

                            console.log(
                                'Setting environment ID array index',
                                localDefaultMeetEnvironmentsForLoading.findIndex(
                                    (id: any) => id.environmentId == json.space.environmentId
                                )
                            );

                            setEnvironmentIdIndex(
                                localDefaultMeetEnvironmentsForLoading.findIndex(
                                    (id: any) => id.environmentId == json.space.environmentId
                                )
                            );
                        });

                    //handle on load skybox logic
                    let skyboxArray: Array<SkyBox> = [];
                    const KEY_SKYBOX_SPACE_STATE = 'skyboxSpaceState' + json.space?.environment?.idName ?? 'unknown';
                    if (json.state[KEY_SKYBOX_SPACE_STATE]) {
                        getApi(`/environment/${json.space.environmentId}/extras`).then(async (response) => {
                            const extras = await response.json();
                            if (extras.nwr_properties_scene.skyboxes.files[0]) {
                                const files = extras.nwr_properties_scene.skyboxes.files;
                                const filesEnum = extras.nwr_properties_scene.skyboxes.files_enum as number;
                                console.log(
                                    'MEET:: Init: Skybox Space State Exists: GLTF Extras: ',
                                    extras,
                                    'files: ',
                                    files
                                );

                                const defaultSkyboxes = files.map((file: any) => {
                                    if (file.jpg_path) {
                                        return new DefaultSkyBox(
                                            file.export_name,
                                            undefined,
                                            false,
                                            false,
                                            'env',
                                            false,
                                            file.export_name + '.env',
                                            file.jpg_path
                                        );
                                    } else {
                                        return new DefaultSkyBox(
                                            file.export_name,
                                            file.path,
                                            false,
                                            false,
                                            'env',
                                            false,
                                            file.export_name + '.env',
                                            file.optional_image_path
                                        );
                                    }
                                });
                                console.log(
                                    'MEET:: Init: Skybox Space State Exists, Default Skybox Array Created: ',
                                    defaultSkyboxes
                                );

                                fixDefaultSkyboxNames(defaultSkyboxes, json, filesEnum);

                                setDefaultSkyBoxArray(defaultSkyboxes);

                                skyboxArray = json.state[KEY_SKYBOX_SPACE_STATE].value.skyboxArray;

                                console.log('MEET:: Init: Skybox Space State Exists, skyboxArray: ', skyboxArray);
                                setSkyBoxArray(skyboxArray);
                            } else {
                                getApi(`/environment/${json.space.environmentId}/skybox/defaults`).then(async (r3) => {
                                    const json3 = await r3.json();
                                    if (!r3.ok) {
                                        console.log(json3.message || 'Could not get space data');
                                    }

                                    const mappedSkyboxes = json3.map((sb: any) => ({
                                        filetype: 'env',
                                        isActive: false,
                                        isDefault: false,
                                        name: sb.name,
                                        url: sb.url,
                                        envName: sb.name
                                    }));
                                    console.log(
                                        'MEET:: Init: Skybox Space State Exists, Default Skybox Array Created: ',
                                        mappedSkyboxes
                                    );

                                    fixDefaultSkyboxNames(mappedSkyboxes, json);
                                    setDefaultSkyBoxArray(mappedSkyboxes);

                                    skyboxArray = json.state[KEY_SKYBOX_SPACE_STATE].value.skyboxArray;

                                    console.log('MEET:: Init: Skybox Space State Exists, skyboxArray: ', skyboxArray);
                                    setSkyBoxArray(skyboxArray);
                                });
                            }
                        });
                    } else {
                        getApi(`/environment/${json.space.environmentId}/extras`).then(async (response) => {
                            const extras = await response.json();
                            console.log('MEET:: request extras: ', extras);
                            if (extras.nwr_properties_scene.skyboxes.files[0]) {
                                const files = extras.nwr_properties_scene.skyboxes.files;
                                console.log('MEET:: request extras:  files: ', files);
                                const filesEnum = extras.nwr_properties_scene.skyboxes.files_enum as number;
                                const defaultSkyboxes = files.map((file: any) => {
                                    console.log('MEET:: mapping files:  file: ', file);
                                    if (file.jpg_path) {
                                        return new DefaultSkyBox(
                                            file.export_name,
                                            undefined,
                                            false,
                                            false,
                                            'env',
                                            false,
                                            file.export_name + '.env',
                                            file.jpg_path
                                        );
                                    } else {
                                        return new DefaultSkyBox(
                                            file.export_name,
                                            file.path,
                                            false,
                                            false,
                                            'env',
                                            false,
                                            file.export_name + '.env',
                                            file.optional_image_path
                                        );
                                    }
                                });

                                if (!defaultSkyboxes[0] || (!defaultSkyboxes[0].url && !defaultSkyboxes[0].jpgUrl)) {
                                    console.error('MEET:: No default skybox found');
                                    //  setShowErrorText(true);
                                    setShowSkyboxLoadingError(true);
                                    setLoading(false);
                                }
                                console.log(
                                    'MEET:: Init: Space State does not exist, Default Skybox Array Created: ',
                                    defaultSkyboxes
                                );

                                fixDefaultSkyboxNames(defaultSkyboxes, json, filesEnum);

                                setDefaultSkyBoxArray(defaultSkyboxes);

                                if (defaultSkyboxes[filesEnum]) {
                                    defaultSkyboxes[filesEnum].isActive = true;
                                    setSkyBoxArray(defaultSkyboxes);
                                    /*                      await skyboxChangeUrl(
                                        defaultSkyboxes[filesEnum].url,
                                        scene,
                                        defaultSkyboxes,
                                        defaultSkyboxes,
                                        defaultSkyboxes[filesEnum].jpgUrl,
                                        json.space
                                    ); */
                                    if (defaultSkyboxes[filesEnum].url) {
                                        setSkyboxUrl(defaultSkyboxes[filesEnum].url);
                                    } else {
                                        setSkyboxUrl(defaultSkyboxes[filesEnum].jpgUrl);
                                    }
                                } else if (defaultSkyboxes[0] && defaultSkyboxes[0].url) {
                                    console.error('MEET:: No environment.env skybox found, trying first in array');
                                    defaultSkyboxes[0].isActive = true;
                                    setSkyBoxArray(defaultSkyboxes);
                                    /*             await skyboxChangeUrl(
                                        defaultSkyboxes[0].url,
                                        scene,
                                        defaultSkyboxes,
                                        defaultSkyboxes,
                                        defaultSkyboxes[0].jpgUrl,
                                        json.space
                                    ); */
                                    setSkyboxUrl(defaultSkyboxes[0].url);
                                }
                            } else {
                                //if there is no space state we need to set our local values, and change the skybox to match them. Space state will be set in 3d once the skybox change event handler is called for the first time
                                getApi(`/environment/${json.space.environmentId}/skybox/defaults`).then(async (r3) => {
                                    const json3 = await r3.json();
                                    if (!r3.ok) {
                                        console.log(json3.message || 'Could not get space data');
                                    }
                                    console.log(
                                        'MEET:: Space State does not exist, get environment skybox defaults: ',
                                        json3
                                    );
                                    const mappedSkyboxes = json3.map((sb: any) => ({
                                        filetype: 'env',
                                        isActive: false,
                                        isDefault: true,
                                        name: sb.name,
                                        url: sb.url,
                                        envName: sb.name
                                    }));
                                    console.log(
                                        'MEET:: Init: Space State does not exist, Default Skybox Array Created: ',
                                        mappedSkyboxes
                                    );

                                    if (!mappedSkyboxes[0] || !mappedSkyboxes[0].url) {
                                        console.error('MEET:: No default skybox found');
                                        //  setShowErrorText(true);
                                        setShowSkyboxLoadingError(true);
                                        setLoading(false);
                                    }

                                    fixDefaultSkyboxNames(mappedSkyboxes, json);
                                    setDefaultSkyBoxArray(mappedSkyboxes);

                                    const defaultIndex = mappedSkyboxes.findIndex(
                                        (skybox: { envName: string }) => skybox.envName === 'environment.env'
                                    );

                                    if (mappedSkyboxes[defaultIndex]) {
                                        mappedSkyboxes[defaultIndex].isActive = true;
                                        setSkyBoxArray(mappedSkyboxes);
                                        /*                              await skyboxChangeUrl(
                                            mappedSkyboxes[defaultIndex].url,
                                            scene,
                                            skyboxArray,
                                            mappedSkyboxes
                                        ); */
                                        setSkyboxUrl(mappedSkyboxes[defaultIndex].url);
                                    } else if (mappedSkyboxes[0] && mappedSkyboxes[0].url) {
                                        console.error('MEET:: No environment.env skybox found, trying first in array');
                                        mappedSkyboxes[0].isActive = true;
                                        setSkyBoxArray(mappedSkyboxes);
                                        /*                     await skyboxChangeUrl(
                                            mappedSkyboxes[0].url,
                                            scene,
                                            skyboxArray,
                                            mappedSkyboxes
                                        ); */
                                        setSkyboxUrl(mappedSkyboxes[0].url);
                                    }
                                });
                            }
                        });
                    }
                    if (!json.space.inviteCode) {
                        getApi(`/station/${json.space.stationId}`).then(async (r2) => {
                            const json2 = await r2.json();
                            if (!r2.ok) {
                                console.log(json2.message || 'Could not get space data');
                            }

                            setinviteLink(
                                `${window.location.protocol}//${window.location.host}/invite/${json2.station.inviteCode}?spaceId=${json.space.id}`
                            );
                        });
                    } else {
                        setinviteLink(
                            `${window.location.protocol}//${window.location.host}/invite/${json.space!
                                .inviteCode!}?spaceId=${json.space.id}`
                        );
                    }
                } else {
                    console.log('Space data missing from response');
                }
            })
            .catch((err) => {
                console.error(err), setShowErrorText(true);
            });

        // Check if the canvas ref is defined
        if (!reactCanvas.current) return;
        // Create a new Babylon.js engine using the canvas element
        const engine = new Engine(
            reactCanvas.current,
            antialias,
            {
                powerPreference: 'high-performance'
            },
            adaptToDeviceRatio
        );
        console.log('NEW ENGINE CREATED');
        // Create a new Babylon.js scene using the engine
        const scene = new Scene(engine, sceneOptions);
        scene.skipFrustumClipping = true;
        scene.skipPointerMovePicking = true;

        // Check if the scene is ready
        if (scene.isReady()) {
            if (SCENE_DEBUG) {
                scene.debugLayer.show({
                    showExplorer: true,
                    showInspector: true,
                    overlay: true
                });
            }

            // If the scene is ready, call the onSceneReady function
            if (onSceneReady) onSceneReady(scene);
            scene.onPointerObservable.add((pointerInfo) => {
                switch (pointerInfo.type) {
                    case PointerEventTypes.POINTERDOWN:
                        pauseAnimationHandlerClick(scene);
                        setIsMenuOpen(false);
                        break;
                    case PointerEventTypes.POINTERUP:
                        startAnimationHandlerScene(scene);
                        break;
                }
            });
            setScene(scene);
        } else {
            // If the scene is not ready yet, add an observer to call the onSceneReady function once the scene is ready
            if (onSceneReady) scene.onReadyObservable.addOnce((scene) => onSceneReady(scene));
        }

        // Set up a resize function to resize the engine when the window is resized
        const resize = () => {
            scene.getEngine().resize();
        };
        if (window) {
            window.addEventListener('resize', resize);
        }
        // Return a cleanup function to dispose of the engine and remove the event listener when the component is unmounted
        return () => {
            scene.getEngine().dispose();
            if (window) {
                window.removeEventListener('resize', resize);
            }
        };
    }, [antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady]);

    useEffect(() => {
        const handleConversion = async (scene: Scene) => {
            if (!sbGenResults) return;
            if (!sbGenResults.url) return;
            console.log('sbGenResults changed');
            setSkyboxProgressString('Magic! Turning pictures to a new world! (You may experience freezes)');
            try {
                console.log('sbGenResults changed, handling conversion creating env file from jpg');
                const envTextureLow = await createEnvTextureFromJpg(sbGenResults.url, 128, scene);
                const envTextureMed = await createEnvTextureFromJpg(sbGenResults.url, 512, scene);
                const envTextureHigh = await createEnvTextureFromJpg(sbGenResults.url, 1024, scene);
                if (!envTextureLow) {
                    console.error('createEnvTextureFromJpg completed, but no envTexture was returned');
                    setShowErrorText(true);
                    return;
                }

                const assetArrayBuffer = await Tools.LoadFileAsync(sbGenResults.url, true);
                const assetBlob = new Blob([assetArrayBuffer]);
                const urlForJpg = await uploadJpg(assetBlob);
                uploadEnv(sbGenResults.id, envTextureLow, envTextureMed, envTextureHigh, urlForJpg);
            } catch (error) {
                console.error('Error creating environment texture:', error);
                setShowErrorText(true);
            }
        };

        handleConversion(scene); // replace "yourScene" with your actual scene object
    }, [sbGenResults]);

    useEffect(() => {
        console.log('default meet environents', defaultMeetEnvironments);
    }, [defaultMeetEnvironments]);

    useEffect(() => {
        console.log('ENVIRONMENT ID INDEX CHANGED: ', environmentIdIndex);
    }, [environmentIdIndex]);

    useEffect(() => {
        console.log('space updated', space);

        if (!space.environmentId) return;
        UpdateSpaceIdDependents();
        if (!forrestModel.uniqueId || !rooftopModel.uniqueId || !miniMeet.uniqueId) return;
        set3dModelVisibility(environmentId);
    }, [space]);

    const set3dModelVisibility = (environmentID: string) => {
        console.log('setting 3d model visibility: environmentId = ', environmentID, forrestModel, miniMeet);

        const matchingDefaultMeetEnv = defaultMeetEnvironments.find((envId) => {
            if (envId.environmentId == environmentID) {
                return envId;
            }
        });

        if (matchingDefaultMeetEnv) {
            console.log('This is a default environment');
            if (matchingDefaultMeetEnv.idName == FOREST_ENV_ID_NAME) {
                console.log('This is forrest');

                forrestModel.setEnabled(true);
                rooftopModel.setEnabled(false);
                miniMeet.setEnabled(false);
                camera.position = FORREST_CAMERA_POS;
                camera.setTarget(FORREST_CAMERA_TARGET);
            } else if (matchingDefaultMeetEnv.idName == MINI_ENV_ID_NAME) {
                console.log('This is mini meet');

                forrestModel.setEnabled(false);
                rooftopModel.setEnabled(false);
                miniMeet.setEnabled(true);
                camera.setTarget(MINI_MEET_CAMERA_TARGET);
                camera.position = MINI_MEET_CAMERA_POS;
            } else if (matchingDefaultMeetEnv.idName == ROOFTOP_ENV_NAME) {
                console.log('This is a rooftop');

                forrestModel.setEnabled(false);
                miniMeet.setEnabled(false);
                rooftopModel.setEnabled(true);
                camera.setTarget(ROOFTOP_CAMERA_TARGET);
                camera.position = ROOFTOP_CAMERA_POS;
            } else {
                console.log('This is unknown environment');

                forrestModel.setEnabled(false);
                miniMeet.setEnabled(false);
                rooftopModel.setEnabled(false);
            }
        } else {
            console.log('This is a custom environment');
            forrestModel.setEnabled(false);
            miniMeet.setEnabled(false);
            rooftopModel.setEnabled(false);
        }
    };

    const fixDefaultSkyboxNames = (mappedSkyboxes: any, json?: any, defaultSkyboxIndex?: number) => {
        console.log(
            'MEET:: fixDefaultSkyboxNames: mappedSkyboxes: ',
            mappedSkyboxes,
            'defaultSkyboxIndex: ',
            defaultSkyboxIndex
        );
        mappedSkyboxes.forEach((skybox: { name: string }) => {
            skybox.name = skybox.name.split('.').shift() || ''; //remove .env from the names
            skybox.name = skybox.name.replaceAll('_', ' ');
        });
        let defaultSkyboxName = '';
        if (json) {
            defaultSkyboxName = 'Default - ' + json.space.environment.displayName;
        } else {
            defaultSkyboxName = 'Default - ' + space.environment?.displayName;
        }
        if (mappedSkyboxes.length > 0) {
            const useProvidedDefaultIndex = (defaultSkyboxIndex ?? -1) > -1;
            console.log('MEET:: fixDefaultSkyboxNames: useProvidedDefaultIndex: ', useProvidedDefaultIndex);
            const defaultIndex = useProvidedDefaultIndex
                ? defaultSkyboxIndex
                : mappedSkyboxes.findIndex((skybox: { name: string }) => skybox.name === 'environment');
            console.log('MEET:: fixDefaultSkyboxNames: defaultIndex: ', defaultIndex);
            if (defaultIndex != -1) {
                console.log('MEET:: fixDefaultSkyboxNames: Changing default skybox name: ', defaultSkyboxName);
                mappedSkyboxes[defaultIndex].name = defaultSkyboxName;
            }
        }
    };

    const UpdateSpaceIdDependents = async () => {
        try {
            const KEY_SKYBOX_SPACE_STATE = 'skyboxSpaceState' + space?.environment?.idName ?? 'unknown';
            if (space.environmentId) {
                console.log('MEET:: Updating Space Id Dependents: space: ', space);
                setWorldName(space.title);

                getApi(`/environment/${space.environmentId}/extras`).then(async (response) => {
                    const extras = await response.json();
                    if (extras.nwr_properties_scene.skyboxes.files[0]) {
                        const files = extras.nwr_properties_scene.skyboxes.files;
                        const filesEnum = extras.nwr_properties_scene.skyboxes.files_enum as number;
                        console.log('MEET:: UpdateSpaceIdDependents: GLTF Extras: ', extras, 'files: ', files);

                        //turn files array into an object so that it can be mapped

                        const defaultSkyboxes = files.map((file: any) => {
                            if (file.jpg_path) {
                                console.log('SKYBOX:: adding jpg only skybox to default skybox list: file: ', file);
                                return new DefaultSkyBox(
                                    file.export_name,
                                    undefined,
                                    false,
                                    false,
                                    'env',
                                    false,
                                    file.export_name + '.env',
                                    file.jpg_path
                                );
                            } else {
                                return new DefaultSkyBox(
                                    file.export_name,
                                    file.path,
                                    false,
                                    false,
                                    'env',
                                    false,
                                    file.export_name + '.env',
                                    file.optional_image_path
                                );
                            }
                        });

                        console.log('MEET:: UpdateSpaceIdDependents: defaultSkyboxes: ', defaultSkyboxes);
                        fixDefaultSkyboxNames(defaultSkyboxes, undefined, filesEnum);
                        setDefaultSkyBoxArray(defaultSkyboxes);

                        console.log('DOES SKYBOX SPACE STATE EXIST?: ', spaceState[KEY_SKYBOX_SPACE_STATE]);

                        let skyboxArray: Array<SkyBox> = [];

                        if (spaceState[KEY_SKYBOX_SPACE_STATE]) {
                            skyboxArray = spaceState[KEY_SKYBOX_SPACE_STATE].value.skyboxArray;

                            let activeSkyboxIndexTemp = skyboxArray.findIndex((skybox: { isActive: any }) => {
                                if (skybox.isActive) {
                                    return true;
                                }
                                return false;
                            });
                            if (activeSkyboxIndexTemp === -1) {
                                activeSkyboxIndexTemp = 0;
                            }
                            setSkyBoxArray(skyboxArray);

                            if (skyboxArray[activeSkyboxIndexTemp]) {
                                console.log(
                                    'SKYBOX CHANGING AFTER ENV_ID UPDATE: ',
                                    skyboxArray[activeSkyboxIndexTemp]
                                );

                                if (skyboxArray[activeSkyboxIndexTemp].jpgUrl) {
                                    if (skyboxArray[activeSkyboxIndexTemp].url) {
                                        setSkyboxUrl(skyboxArray[activeSkyboxIndexTemp].url);
                                    } else {
                                        setSkyboxUrl(skyboxArray[activeSkyboxIndexTemp].jpgUrl);
                                    }

                                    console.log('MEET:: SKYBOX CHANGING: defaultSkyboxes: ', defaultSkyboxes);
                                    const defaultSkyboxIndex = defaultSkyboxes.findIndex(
                                        (skybox: DefaultSkyBox) =>
                                            skyboxArray[activeSkyboxIndexTemp].url?.includes(
                                                skybox.envName ||
                                                    'THIS WILL NEVER BE IN A URL EVER STRING ^&^%&^&^%%$^%&%^&%^#$%@$!@!!#$%$%#^$^%&%$^&DSFAGSEDGFAWESFASDFSF'
                                            ) ||
                                            skybox.jpgUrl?.includes(
                                                skyboxArray[activeSkyboxIndexTemp].jpgUrl ||
                                                    'THIS WILL NEVER BE IN A URL EVER STRING ^&^%&^&^%%$^%&%^&%^#$%@$!@!!#$%$%#^$^%&%$^&DSFAGSEDGFAWESFASDFSF'
                                            )
                                    );

                                    const jpgUrl = defaultSkyboxes[defaultSkyboxIndex]
                                        ? defaultSkyboxes[defaultSkyboxIndex]?.jpgUrl
                                        : skyboxArray[activeSkyboxIndexTemp].jpgUrl
                                        ? skyboxArray[activeSkyboxIndexTemp].jpgUrl
                                        : undefined;
                                    const envUrl = defaultSkyboxes[defaultSkyboxIndex]
                                        ? defaultSkyboxes[defaultSkyboxIndex]?.url
                                        : skyboxArray[activeSkyboxIndexTemp].url
                                        ? skyboxArray[activeSkyboxIndexTemp].url
                                        : undefined;

                                    console.log('MEET:: SKYBOX CHANGING: envUrl: ', envUrl, 'jpgUlr: ', jpgUrl);
                                    await skyboxChangeUrl(envUrl, scene, skyboxArray, defaultSkyboxes, jpgUrl);
                                    console.log('MEET:: resolved skybox change url');
                                } else {
                                    if (skyboxArray[activeSkyboxIndexTemp].url) {
                                        setSkyboxUrl(skyboxArray[activeSkyboxIndexTemp].url);
                                        console.log(
                                            'MEET:: SKYBOX CHANGING: url: ',
                                            skyboxArray[activeSkyboxIndexTemp].url
                                        );
                                        await skyboxChangeUrl(
                                            skyboxArray[activeSkyboxIndexTemp].url,
                                            scene,
                                            skyboxArray,
                                            defaultSkyboxes
                                        );
                                        console.log('MEET:: resolved skybox change url');
                                    }
                                }
                            }
                        } else {
                            setSkyBoxArray(defaultSkyboxes);
                            const defaultIndex = defaultSkyboxes[filesEnum] ? filesEnum : 0;
                            console.log(
                                'MEET:: SKYBOX CHANGING: defaultIndex: ',
                                defaultIndex,
                                'defaultSkyboxes: ',
                                defaultSkyboxes
                            );
                            if (defaultSkyboxes[defaultIndex]?.jpgUrl) {
                                console.log('SKYBOX CHANGING: ', defaultSkyboxes[defaultIndex].jpgUrl);
                                if (defaultSkyboxes[defaultIndex].url) {
                                    setSkyboxUrl(defaultSkyboxes[defaultIndex].url);
                                } else {
                                    setSkyboxUrl(defaultSkyboxes[defaultIndex].jgpUrl);
                                }
                                await skyboxChangeUrl(
                                    undefined,
                                    scene,
                                    defaultSkyboxes,
                                    defaultSkyboxes,
                                    defaultSkyboxes[defaultIndex].jpgUrl
                                );
                            } else if (defaultSkyboxes[defaultIndex]?.url) {
                                console.log('SKYBOX CHANGING: ', defaultSkyboxes[defaultIndex].url);
                                setSkyboxUrl(defaultSkyboxes[defaultIndex].url);
                                await skyboxChangeUrl(
                                    defaultSkyboxes[defaultIndex].url,
                                    scene,
                                    defaultSkyboxes,
                                    defaultSkyboxes
                                );
                            }
                        }
                    } else {
                        // get default skybox list for new environment id
                        getApi(`/environment/${space.environmentId}/skybox/defaults`).then(async (r3) => {
                            const json3 = await r3.json();
                            if (!r3.ok) {
                                console.log(json3.message || 'Could not get space data');
                            }

                            const mappedSkyboxes = json3.map((sb: any) => ({
                                filetype: 'env',
                                isActive: false,
                                isDefault: false,
                                name: sb.name,
                                url: sb.url,
                                envName: sb.name
                            }));

                            console.log(
                                'MEET:: Updating Space Id Dependents: mapped default Skyboxes: ',
                                mappedSkyboxes
                            );

                            fixDefaultSkyboxNames(mappedSkyboxes);
                            //save reference to default skybox full urls
                            setDefaultSkyBoxArray(mappedSkyboxes);

                            console.log('DOES SKYBOX SPACE STATE EXIST?: ', spaceState[KEY_SKYBOX_SPACE_STATE]);

                            let skyboxArray: Array<SkyBox> = [];

                            if (spaceState[KEY_SKYBOX_SPACE_STATE]) {
                                skyboxArray = spaceState[KEY_SKYBOX_SPACE_STATE].value.skyboxArray;

                                const activeSkyboxIndexTemp = skyboxArray.findIndex((skybox: { isActive: any }) => {
                                    if (skybox.isActive) {
                                        return true;
                                    }
                                    return false;
                                });

                                setSkyBoxArray(skyboxArray);

                                if (skyboxArray[activeSkyboxIndexTemp] && skyboxArray[activeSkyboxIndexTemp].url) {
                                    setSkyboxUrl(skyboxArray[activeSkyboxIndexTemp].url);
                                    console.log(
                                        'SKYBOX CHANGING AFTER ENV_ID UPDATE: ',
                                        skyboxArray[activeSkyboxIndexTemp].url
                                    );
                                    await skyboxChangeUrl(
                                        skyboxArray[activeSkyboxIndexTemp].url,
                                        scene,
                                        skyboxArray,
                                        mappedSkyboxes
                                    );
                                    console.log('MEET:: resolved skybox change url');
                                }
                            } else {
                                const defaultIndex = mappedSkyboxes.findIndex(
                                    (skybox: { envName: string }) => skybox.envName === 'environment.env'
                                );
                                setSkyBoxArray(mappedSkyboxes);
                                if (mappedSkyboxes[defaultIndex]) {
                                    console.log('SKYBOX CHANGING: ', mappedSkyboxes[defaultIndex].url);
                                    await skyboxChangeUrl(
                                        mappedSkyboxes[defaultIndex].url,
                                        scene,
                                        skyboxArray,
                                        mappedSkyboxes
                                    );
                                }
                            }
                        });
                    }
                });
            }
        } catch (error) {
            console.error(error);
            setShowErrorText(true);
        }
    };

    useInterval(() => {
        //console.log(sbGenResults?.status);
        if (sbGenResults == null) return;
        // console.log('Processing...', sbGenResults);
        if (['PENDING', 'DISPATCHED', 'PROCESSING'].includes(sbGenResults?.status)) {
            console.log('Processing...', sbGenResults?.status);
            getApi(`/textToImage/skybox/${sbGenResults.id}`).then(async (r) => {
                const json = await r.json();
                if (!r.ok) {
                    console.log(json.message || 'Could not get skybox gen status');
                } else {
                    if (json.status === 'COMPLETE' && json.url) {
                        console.log('AI Skybox generation COMPLETE, json now has url: ', json.url);
                        setSkyboxProgressString('We’re close! :: Note, conversion is taxing on your device.');
                    }
                    setSbGenResults(json);
                }
            });
        }
        if (sbGenResults?.status === 'ERROR') {
            // do something here
            console.log('AI Skybox generation error.');
            setSkyboxProgressString('Error. Try again.');
            setTimeout(() => {
                setPreLoadingGen(false);
                setTimeout(() => {
                    setLoadingGen(false);
                    setSbGenResults(null);
                    setGeneratingSkybox(false);
                }, 1000);
            }, 1000);
        }

        if (sbGenResults?.status === 'PROCESSING') {
            // do something here
            setSkyboxProgressString('This may take a few minutes. Cancel & Start Meet to set skybox later.');
        }

        /*         if (sbGenResults?.status === 'COMPLETE' && sbGenResults.url) {
            // do something here
            if(!pollRequestComplete){
            console.log('AI Skybox generation COMPLETE, json now has url.');
            setSkyboxProgressString('We’re close! :: Note, conversion is taxing on your device.');
            }
        } */
    }, 2000);

    const genSkybox = (prompt: string, skyboxType = skyboxGenType.id) => {
        setIsSettingsRowOpened(false);
        setGeneratingSkybox(true);
        pauseAnimationHandler();
        setSkyboxProgressString('Sending to the ai clouds!');
        postApi(`/textToImage/${space.id}/skybox?client=2D`, { prompt, skyboxType }).then(async (r) => {
            const json = await r.json();
            if (!r.ok) {
                console.log(json.message || 'Could not gen skybox');
                return;
            }
            if (json) {
                console.log('GenSkybox', json);
                setSbGenResults(json);
            }
        });
    };

    const setSpaceRemote = async (value: { [key: string]: any }) => {
        return new Promise((resolve, reject) => {
            const updatedSpace = { ...value, isActive: space.isActive, isPrivate: space.isPrivate };
            postApi(`/space/${space.id}/update`, updatedSpace)
                .then(async (r) => {
                    const json = await r.json();
                    if (r.ok && json) {
                        console.log('Space Update Result: ', json);
                        resolve(json);
                    } else {
                        const errorMessage = json.message || 'Could not update space';
                        console.log(errorMessage);
                        reject(new Error(errorMessage));
                    }
                })
                .catch((err) => {
                    console.log('Could not update state:', err);
                    reject(err);
                });
        });
    };

    const setSpaceStateRemote = async (key: string, value: { [key: string]: any }, spaceID?: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            const endpoint = spaceID ? `/space/${spaceID}/state/${key}` : `/space/${space.id}/state/${key}`;

            putApi(endpoint, value)
                .then(async (r) => {
                    const json = await r.json();
                    if (!r.ok) {
                        reject(json.message || 'Could not update space state');
                    } else {
                        resolve(json);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    const createEquiRectangularTexture = async (scene: Scene, filepath: string, resolution: number, gamma: boolean) => {
        try {
            return await new Promise<EquiRectangularCubeTexture>((resolve, reject) => {
                const texture = new EquiRectangularCubeTexture(
                    filepath,
                    scene,
                    resolution,
                    false,
                    gamma,
                    () => {
                        resolve(texture);
                    },
                    () => {
                        reject();
                    }
                );
            });
        } catch (e) {
            console.error(e);
            throw new Error('Failed to create equi-rectangular texture');
        }
    };

    const prefilterEquirectangularCubeTextureAsync = async (
        texture: EquiRectangularCubeTexture,
        engine: ThinEngine
    ) => {
        if (!texture) {
            throw new Error('Invalid texture');
        }

        if (!engine) {
            throw new Error('Invalid engine');
        }

        try {
            const filtering = new HDRFiltering(engine);
            await filtering.prefilter(texture, () => {
                texture.gammaSpace = false;
                texture.lodGenerationScale = 0.8;
                console.log('prefiltering done');
            });
            return texture;
        } catch (e) {
            console.error(e);
            throw new Error('Failed to prefilter equirectangular cube texture');
        }
    };

    const createEnvTextureFromJpg = async (
        filepath: string,
        resolution: number,
        scene: Scene,
        callback?: () => void
    ) => {
        if (!filepath || typeof filepath !== 'string') {
            throw new Error('Invalid filepath');
        }

        if (!scene) {
            throw new Error('Invalid scene');
        }

        if (callback && typeof callback !== 'function') {
            throw new Error('Invalid callback');
        }

        try {
            const jpgCubeTexture = await createEquiRectangularTexture(
                scene,
                filepath,
                resolution,
                true //this is confusing -- needs to be set to true if you will be using it with PBR materials
            );

            if (!jpgCubeTexture) {
                throw new Error('Failed to create equi-rectangular texture');
            }

            const prefilteredJpgCubeTexture = await prefilterEquirectangularCubeTextureAsync(
                jpgCubeTexture,
                scene.getEngine()
            );

            const envTextureArgs = {
                imageType: envExportImageTypes[_envOptions.imageTypeIndex].imageType,
                imageQuality: _envOptions.imageQuality
            };

            const buffer = await EnvironmentTextureTools.CreateEnvTextureAsync(
                prefilteredJpgCubeTexture,
                envTextureArgs
            );

            if (!buffer) {
                throw new Error('Failed to create environment texture');
            }

            const blob = new Blob([buffer], { type: 'octet/stream' });
            console.log('CreateEnvTextureAsync: ', buffer, blob);
            // TODO: DEBUGGING REMOVE THIS
            // Tools.Download(blob, `envTextBlob ${Date.now()}`);
            return blob;
        } catch (e) {
            console.error(e);
            throw new Error('Failed to create environment texture from jpg');
        }
    };

    const createPrefilteredCubeTextureAsync = async (filepath: string, scene: Scene | ThinEngine) => {
        return await new Promise<CubeTexture>((resolve, reject) => {
            const texture = new CubeTexture(
                filepath,
                scene,
                undefined,
                undefined,
                undefined,
                () => {
                    resolve(texture);
                },
                () => {
                    reject();
                },
                undefined,
                true,
                '.env',
                false,
                undefined,
                undefined
            );
        });
    };

    const skyboxChangeUrl = async (
        url: string | undefined,
        scene: Scene,
        skyboxArray?: SkyBox[],
        defualtSkyboxArray?: DefaultSkyBox[],
        jpgUrlLocal?: string,
        localSpace?: any
    ): Promise<void> => {
        let useJpgLink = false;
        let jpgUrl = null;
        let name = '';
        try {
            console.log('MEET:: start skyboxChangeUrl: url: ', url, ' jpgUrl: ', jpgUrlLocal, ' name: ', name);
            setShowSkyboxLoadingError(false);
            if (defualtSkyboxArray && skyboxArray) {
                console.log(
                    'MEET:: defualtSkyboxArray: ',
                    defualtSkyboxArray,
                    ' && skyboxArray: ',
                    skyboxArray,
                    ' supplied '
                );
                skyboxArray.forEach((skybox) => {
                    if ((url && skybox.url === url) || (jpgUrlLocal && skybox.jpgUrl === jpgUrlLocal)) {
                        console.log('MEET::  found skybox with matching url: ', skybox, ' url: ', url);
                        if (skybox.jpgUrl) {
                            console.log('MEET:: skybox has jpgUrl  ');

                            useJpgLink = true;
                            jpgUrl = skybox.jpgUrl;
                            console.log('MEET:: setting name:  ', skybox.name);
                            name = skybox.name;
                        }

                        defualtSkyboxArray.forEach((defaultSkybox) => {
                            if (
                                defaultSkybox.envName === skybox.url ||
                                (defaultSkybox.jpgUrl &&
                                    defaultSkybox.jpgUrl.includes(skybox.jpgUrl ?? 'dsfsfsdfdsfdesfff'))
                            ) {
                                console.log(
                                    'MEET:: changing to default skybox, default skybox envName: ',
                                    defaultSkybox.envName,
                                    ' and skybox url: ',
                                    skybox.url,
                                    ' are equal. defaultSkybox.name = ',
                                    defaultSkybox.name,
                                    'default skybox: = ',
                                    defaultSkybox
                                );
                                if (defaultSkybox.url) {
                                    url = CORS_ANYWHERE + defaultSkybox.url;
                                }
                                if (defaultSkybox.jpgUrl) {
                                    jpgUrl = CORS_ANYWHERE + defaultSkybox.jpgUrl;
                                }
                                if (defaultSkybox.name) {
                                    console.log('MEET:: setting name:  ', skybox.name);
                                    name = defaultSkybox.name;
                                }
                            }
                        });
                    }
                });
            } else {
                console.log(
                    'MEET:: defualtSkyboxArray && skyboxArray NOT supplied, defaultSkyboxArray: ',
                    defaultSkyBoxArray
                );
                skyBoxArray.forEach((skybox) => {
                    if ((url && skybox.url == url) || (jpgUrlLocal && skybox.jpgUrl === jpgUrlLocal)) {
                        console.log('MEET::  found skybox with matching url: ', skybox);
                        if (skybox.jpgUrl) {
                            console.log('MEET::  skybox has jpgUrl  ');

                            useJpgLink = true;
                            jpgUrl = skybox.jpgUrl;
                            console.log('MEET:: setting name:  ', skybox.name);
                            name = skybox.name;
                        }

                        defaultSkyBoxArray.forEach((defaultSkybox) => {
                            if (defaultSkybox.envName == skybox.url || defaultSkybox.jpgUrl.includes(skybox.jpgUrl)) {
                                console.log(
                                    'MEET:: changing to default skybox, default skybox envName: ',
                                    defaultSkybox.envName,
                                    ' and skybox url: ',
                                    skybox.url,
                                    ' are equal'
                                );
                                if (defaultSkybox.url) {
                                    url = CORS_ANYWHERE + defaultSkybox.url;
                                }
                                if (defaultSkybox.jpgUrl) {
                                    console.log('MEET:: setting jpgUrl:  ', defaultSkybox.jpgUrl);
                                    jpgUrl = CORS_ANYWHERE + defaultSkybox.jpgUrl;
                                }
                                if (defaultSkybox.name) {
                                    console.log('MEET:: setting name:  ', skybox.name);
                                    name = defaultSkybox.name;
                                }
                            }
                        });
                    }
                });
            }

            if (jpgUrlLocal && !jpgUrl) {
                console.log('MEET::  skybox has jpgUrl (using stateful variables): jpgUrl ', jpgUrlLocal);
                useJpgLink = true;
                jpgUrl = jpgUrlLocal;
            }

            console.log('MEET:: skyboxChangeUrl: url: ', url, ' jpgUrl: ', jpgUrl, ' name: ', name);
            if (!url && jpgUrl) {
                console.log("MEET:: skyboxChangeUrl: url doesn't exist, but jpgUrl does: ", jpgUrl);
                const spaceToUse = localSpace ? localSpace : space;

                const cacheKey = 'skybox_' + name + '_1024_' + spaceToUse?.environmentId;
                const cachedSkyboxResponse = await getApi(`/global/state/${cacheKey}`);
                console.log('MEET:: cachedSkyboxResponse', cachedSkyboxResponse);
                const responseJson = await cachedSkyboxResponse.json();
                console.log('MEET:: skyboxChangeUrl: cachedSkyboxResponse: responseJson: ', responseJson);

                //check global state for this skybox

                if (responseJson?.value && responseJson?.value?.url) {
                    console.log('MEET:: skyboxChangeUrl: url found in global state: ', responseJson.value.url);
                    url = responseJson.value.url;
                } else {
                    console.log(
                        'MEET:: skyboxChangeUrl: url not found in global state, converting jpg to env and uploading to global state'
                    );
                    const timestamp = new Date().getTime();

                    const uniqueFileName = name + timestamp;
                    console.log('MEET:: skyboxChangeUrl: createEnvTextureFromJpg: jpgUrl: ', jpgUrl);
                    const envTextureHigh = await createEnvTextureFromJpg(jpgUrl, 1024, scene);
                    console.log(
                        'MEET:: skyboxChangeUrl: createEnvTextureFromJpg done: envTextureHigh: ',
                        envTextureHigh
                    );
                    const b64 = await toBase64(envTextureHigh);
                    console.log('MEET:: skyboxChangeUrl: createEnvTextureFromJpg to b64 done: b64: ', b64);
                    const data = {
                        path: 'spaceState',
                        fileType: 'env',
                        fileData: b64
                    };
                    console.log(
                        'MEET:: skyboxChangeUrl: createEnvTextureFromJpg to data done: data: uploading... ',
                        data
                    );

                    await putApi('/file/' + spaceToUse.id + '/' + uniqueFileName + '.env', data).then(async (r) => {
                        const json = await r.json();
                        if (!r.ok) {
                            console.log(json.message || 'Could not upload new env file');
                        }
                        console.log('MEET:: new default skybox conversion uploadFile res: ', json);
                        url = json.url ?? '';

                        console.log(
                            'MEET:: new default skybox url being added to global state: url: ',
                            url,
                            'cacheKey: ',
                            cacheKey
                        );
                        //upload this url to global state
                        putApi(`/global/state/${cacheKey}`, { url: url }).then(async (r) => {
                            const json = await r.json();
                            if (!r.ok) {
                                console.log(json.message || 'Could not set global state');
                            }
                            console.log('MEET:: new default skybox conversion uploadFile res: ', json);
                        });
                    });
                }
            }

            if (!url) {
                url = '';
            }

            console.log('MEET::  skyboxChangeUrl: creating env with this url: ', url);
            const customSkyboxTextureTemp = await createPrefilteredCubeTextureAsync(url, scene);
            let customSkyboxMesh: Nullable<Mesh> = null;
            if (useJpgLink && jpgUrl) {
                //add a try catch here
                try {
                    const newPhotoDome = spawnPhotoDome(jpgUrl, scene, customSkyboxTextureTemp);
                    customSkyboxMesh = newPhotoDome.mesh;
                } catch (error) {
                    console.error('MEET::  error spawning photodome: ', error);
                    setLoading(false);
                    setLoadingGen(false);
                    setLoadingEnv(false);
                    setLoadingSkybox(false);
                    setShowSkyboxLoadingError(true);
                    throw error;
                }
            } else {
                console.log('MEET::  default skybox created', jpgUrl);
                scene.environmentTexture = customSkyboxTextureTemp;
                customSkyboxMesh = scene.createDefaultSkybox(customSkyboxTextureTemp, true, 50000, undefined, false);
                if (customSkyboxMesh) {
                    customSkyboxMesh.applyFog = false;
                    disposeOldSkyboxes(customSkyboxMesh, scene);
                }
            }
            //   console.log('LAG:: Changing skybox, creating prefilterd cube texture async');

            //    console.log('LAG:: Changing skybox, creating prefilterd cube texture async done');

            // Add rotation animation to skybox
            const animation = new Animation(
                'skyboxRotationAnimation',
                'rotation.y',
                60,
                Animation.ANIMATIONTYPE_FLOAT,
                Animation.ANIMATIONLOOPMODE_CYCLE
            );
            const keys = [
                {
                    frame: 0,
                    value: 0
                },
                {
                    frame: 10000,
                    value: Math.PI * 2
                }
            ];
            animation.setKeys(keys);
            if (!customSkyboxMesh) {
                console.log('MEET:: no customSkyboxMesh, returing');
                return;
            }
            customSkyboxMesh.animations.push(animation);
            scene.beginAnimation(customSkyboxMesh, 0, 10000, true);
            startAnimationHandler();
            return new Promise<void>((resolve) => {
                setTimeout(() => {
                    resolve();
                    setGeneratingSkybox(false);
                    setSkyboxProgressString('');
                    setPreLoading(false);
                    setPreLoadingGen(false);
                    setPreLoadingEnv(false);
                    setPreLoadingSkybox(false);
                    setTimeout(() => {
                        setLoading(false);
                        setLoadingGen(false);
                        setLoadingEnv(false);
                        setLoadingSkybox(false);
                    }, 1000);
                }, 100);
            });
        } catch (error) {
            console.error('Error on skybox swap: ', error);
            //setShowErrorText(true);
            setLoading(false);
            setLoadingGen(false);
            setLoadingEnv(false);
            setLoadingSkybox(false);
            setShowSkyboxLoadingError(true);
        }
    };

    const spawnPhotoDome = (
        filepath: string,
        scene: Scene,
        customSkyboxTexture: CubeTexture,
        callback?: () => void
    ) => {
        console.log('MEET:: spawning photo dome', filepath);
        let oldPhotoDome: PhotoDome | null = null;
        if (photoDome.mesh) {
            oldPhotoDome = photoDome;
        }
        try {
            const newPhotoDome = new PhotoDome(
                'PhotoDome',
                CORS_ANYWHERE + filepath,
                { size: 5000 },
                scene,
                (error) => {
                    console.log('MEET:: error loading photodome: ', error);
                    newPhotoDome.dispose();
                    setShowSkyboxLoadingError(true);
                    setLoading(false);
                    setLoadingGen(false);
                    setLoadingEnv(false);
                    setLoadingSkybox(false);
                }
            );

            console.log('MEET:: newPhotoDome created:', newPhotoDome.name);
            setPhotoDome(newPhotoDome);
            if (newPhotoDome) {
                newPhotoDome.onLoadObservable.add(() => {
                    console.log('MEET:: onLoadObservable: ');

                    scene.environmentTexture = customSkyboxTexture;

                    if (oldPhotoDome && oldPhotoDome.mesh) {
                        console.log('MEET:: onLoadObservable: disposing of old photodome');
                        oldPhotoDome.dispose();
                    }
                    const skyboxMesh = scene.getMeshByName('hdrSkyBox');
                    if (skyboxMesh) {
                        skyboxMesh.dispose();
                        if (skyboxMesh.material) skyboxMesh.material.dispose();
                    }
                });
            }
            return newPhotoDome;
        } catch (error) {
            console.error('MEET:: error spawning photodome: ', error);
            throw error;
        }
    };

    const disposeOldSkyboxes = (newMesh: AbstractMesh | null, scene: Scene) => {
        //  this._scene.environmentTexture?.dispose();
        const skyboxMesh = scene.getMeshByName('hdrSkyBox');

        if (photoDome.mesh) {
            photoDome.dispose();
        }
        if (skyboxMesh && skyboxMesh != newMesh) {
            //    console.log('disposing skybox mesh');
            skyboxMesh.dispose();
            if (skyboxMesh.material) skyboxMesh.material.dispose();
        }

        if (newMesh === null) {
            //   console.log('disposing all skyboxes');

            if (skyboxMesh) {
                skyboxMesh.dispose();
                if (skyboxMesh.material) skyboxMesh.material.dispose();
            }
        }
    };

    const toBase64 = (file: Blob): Promise<any> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => resolve(reader?.result?.toString() ?? '');
            reader.onerror = (error) => reject(error);
        });

    const uploadEnv = async (
        generationId: string,
        fileLowRes: Blob,
        fileMedRes: Blob,
        fileHighRes: Blob,
        jpgUrlLocal?: string
    ) => {
        try {
            const b64LowRes = await toBase64(fileLowRes);
            const b64MedRes = await toBase64(fileMedRes);
            const b64HighRes = await toBase64(fileHighRes);

            const date = new Date();
            const timestamp = date.getTime();
            const dataLowRes = {
                path: 'spaceState',
                fileType: 'env',
                fileData: b64LowRes,
                additionalParams: { generationId }
            };
            const dataMedRes = {
                path: 'spaceState',
                fileType: 'env',
                fileData: b64MedRes,
                additionalParams: { generationId }
            };
            const dataHighRes = {
                path: 'spaceState',
                fileType: 'env',
                fileData: b64HighRes,
                additionalParams: { generationId }
            };

            //const uploadResult = await eventBridge.uploadFile(prompt.replace(/[^a-zA-Z0-9]/g, '_') + timestamp, 'env', b64);
            const fileName = prompt.replace(/[^a-zA-Z0-9]/g, '_');

            const uniqueFileName = fileName + timestamp;

            let medResEnvUrl = '';
            let highResEnvUrl = '';

            await putApi('/file/' + space.id + '/' + uniqueFileName + '.env', dataHighRes).then(async (r) => {
                const json = await r.json();
                if (!r.ok) {
                    console.log(json.message || 'Could not get space data');
                }
                console.log('SubSettingsView uploadFile res: ', json);

                const url = json.url ?? '';
                if (url === '') {
                    console.warn('uploadFile medResEnv.url is empty');
                    return;
                }
                highResEnvUrl = url;
                console.log('Upload Complete Med Res Env: ' + url);
            });

            await putApi('/file/' + space.id + '/' + uniqueFileName + '.env', dataMedRes).then(async (r) => {
                const json = await r.json();
                if (!r.ok) {
                    console.log(json.message || 'Could not get space data');
                }
                console.log('SubSettingsView uploadFile res: ', json);

                const url = json.url ?? '';
                if (url === '') {
                    console.warn('uploadFile medResEnv.url is empty');
                    return;
                }
                medResEnvUrl = url;
                console.log('Upload Complete Med Res Env: ' + url);
            });

            await putApi('/file/' + space.id + '/' + uniqueFileName + '.env', dataLowRes).then(async (r) => {
                const json = await r.json();
                if (!r.ok) {
                    console.log(json.message || 'Could not get space data');
                }
                console.log('SubSettingsView uploadFile res: ', json);

                const url = json.url ?? '';
                if (url === '') {
                    console.error('uploadFile res.url is empty');
                    return;
                }
                setSkyboxUrl(url);
                console.log('Upload Complete: ' + url);
                await setSkyBoxInSpaceState(url, jpgUrlLocal, medResEnvUrl, highResEnvUrl);
                setSkyboxProgressString('Almost there! Painting the sky');
                await skyboxChangeUrl(url, scene, undefined, undefined, jpgUrlLocal);
                return true;
            });
        } catch (error) {
            console.error('Error uploading file:', error);
            setShowErrorText(true);
        }
    };

    const uploadJpg = async (file: Blob) => {
        try {
            const b64 = await toBase64(file);

            const date = new Date();
            const timestamp = date.getTime();
            const data = { path: 'spaceState', fileType: 'jpg', fileData: b64 };

            //const uploadResult = await eventBridge.uploadFile(prompt.replace(/[^a-zA-Z0-9]/g, '_') + timestamp, 'env', b64);
            const fileName = prompt.replace(/[^a-zA-Z0-9]/g, '_');

            const uniqueFileName = fileName + timestamp;

            const uploadResult: string = await putApi('/file/' + space.id + '/' + uniqueFileName + '.jpg', data).then(
                async (r) => {
                    const json = await r.json();
                    if (!r.ok) {
                        console.log(json.message || 'Could not get space data');
                    }
                    console.log('SubSettingsView uploadFile res: ', json);

                    const url = json.url ?? '';
                    if (url === '') {
                        console.error('uploadFile res.url is empty');
                        return;
                    }

                    return url;
                }
            );
            return uploadResult;
        } catch (error) {
            console.error('Error uploading file:', error);
            setShowErrorText(true);
        }
    };

    const setSkyboxGenTypeChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const select = event.currentTarget;
        const localSkyboxGenType = skyboxGenTypes.find((type) => type.id == select.value) || {
            id: 5,
            name: 'Digital Painting',
            'max-char': '420',
            image: null,
            sort_order: 1
        };
        console.log('local skybox gen type', select, skyboxGenTypes, localSkyboxGenType);
        setSkyboxGenType({ id: (select.value as unknown) as number, 'max-char': localSkyboxGenType['max-char'] });
    };

    const setSkyboxHandler = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        console.log('MEET:: selected skybox value', event.currentTarget.value);
        const select = event.currentTarget;

        console.log('MEET:: Selected: ', select, 'Selected Value: ', select.value);
        setPreLoadingSkybox(true);
        setLoadingSkybox(true);
        setShowSkyboxLoadingError(false);

        setSkyboxUrl(select.value);

        if (select.value.includes('.env')) {
            await skyboxChangeUrl(select.value, scene, undefined, undefined, undefined);
            setSkyBoxInSpaceState(select.value);
        } else if (select.value.includes('.jpg') || select.value.includes('.jpeg')) {
            {
                await skyboxChangeUrl(undefined, scene, undefined, undefined, select.value);
                setSkyBoxInSpaceState(undefined, select.value);
            }
        }
    };

    const pauseAnimationHandlerClick = async (scene: Scene) => {
        const animations = scene.animatables;
        console.log('pausing animations: ', animations, 'scene', scene);
        if (!animations) return;
        for (let i = 0; i < animations.length; i++) {
            animations[i].pause();
        }
    };

    const startAnimationHandlerScene = (scene: Scene) => {
        const animations = scene.animatables;
        console.log('starting animations: ', animations);
        if (!animations) return;
        animations.forEach((animation) => {
            animation.restart();
        });
    };

    const pauseAnimationHandler = async (event?: React.ChangeEvent<HTMLSelectElement>) => {
        const animations = scene.animatables;
        console.log('pausing animations: ', animations, 'scene', scene);
        if (!animations) return;
        for (let i = 0; i < animations.length; i++) {
            animations[i].pause();
        }
    };

    const startAnimationHandler = (event?: React.ChangeEvent<HTMLSelectElement>) => {
        const animations = scene.animatables;
        console.log('starting animations: ', animations);
        if (!animations) return;
        animations.forEach((animation) => {
            animation.restart();
        });
    };

    const regenerateInviteCodes = async (isManagerCode: boolean) => {
        await regenerateInviteCode(isManagerCode);
        await regenerateMeetInviteCode();
    };

    const regenerateMeetInviteCode = () => {
        putApi(`/meet/space/${space!.id!}/regenInvte`)
            .then(async (regenerateCodeResponse) => {
                if (regenerateCodeResponse.ok) {
                    const data = await regenerateCodeResponse.json();
                    setMeetInviteCode(data.code);
                }
            })
            .catch((e: Error) => {
                console.error(e);
            });
    };

    const regenerateInviteCode = (isManagerCode: boolean) => {
        const endpoint = isManagerCode ? 'regenerateInviteManagerCode' : 'regenerateStationInviteCode';
        const key = isManagerCode ? 'inviteManagerCode' : 'inviteCode';

        postApi(`/station/${space.stationId}/${endpoint}`)
            .then(async (regenerateCodeResponse) => {
                if (regenerateCodeResponse.ok) {
                    const data = await regenerateCodeResponse.json();
                    console.log('regen invite code: ', data.station.inviteCode);
                    setinviteLink(
                        `${window.location.protocol}//${window.location.host}/invite/${data.station.inviteCode}?spaceId=${space.id}`
                    );
                }
            })
            .catch((error) => console.error(error));
    };

    const setEnvironmentHandler = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const select = event.currentTarget;

        let isAnonymous = false;
        if (isAnon(currentUser)) {
            isAnonymous = true;
            setLoginPromptVisible(true);
            setIsSettingsRowOpened(false);
        }
        if (isAnonymous) return;

        let isCustom = false;
        if (select.value == 'Custom') {
            isCustom = true;

            // setUpgradePromptVisible(true);
            const successRoute =
                '/station/latest?fromPage=Meet&fromUserType=' + currentUser.userType + '&userId=' + currentUser.id;
            history.push('/pricing?' + successRoute);
            //  setShowUpgradeSmallBox(true);
            setIsSettingsRowOpened(false);
            /*      setTimeout(() => {
                setShowUpgradeSmallBox(false);
            }, 10000); */
        }
        if (isCustom) return;

        setLoadingEnv(true);
        setPreLoadingEnv(true);
        setChangingEnvironment(true);
        setEnvironmentId(select.value);

        const meetApiUri = (() => {
            switch (spaceStationOrDefault) {
                case SPACE:
                    return `/meet/space/${spaceId}`;
                    break;
                case STATION:
                    return `/meet/station/${stationId}`;
                    break;
                default:
                    return `/meet`;
            }
        })();

        const data = { environmentId: select.value };
        setSpaceRemote(data).then(async () => {
            await getApi(meetApiUri)
                .then(async (r) => {
                    await r.json().then(async (json5) => {
                        if (r.ok && json5) {
                            console.log('Returned Space after being updated', json5.space);
                            setEnvironmentName(json5.space.environment.displayName);
                            setSpace(json5.space);

                            setEnvironmentIdIndex(
                                defaultMeetEnvironments.findIndex(
                                    (id: any) => id.environmentId == json5.space.environmentId
                                )
                            );
                            setChangingEnvironment(false);
                        }
                    });
                })
                .catch((e: Error) => {
                    console.log('Could not get space with error: ', e);
                });
        });
    };

    const setSkyBoxInSpaceState = async (
        sbUrl?: string,
        jpgUrl?: string,
        urlMediumRes?: string,
        urlHighRes?: string
    ) => {
        console.log(
            'MEET:: setSkyBoxInSpaceState: sbUrl: ',
            sbUrl,
            'jpgUrl: ',
            jpgUrl,
            'urlMediumRes: ',
            urlMediumRes,
            'urlHighRes: ',
            urlHighRes,
            'skybox array: ',
            skyBoxArray
        );

        const nSpaceState = spaceState;
        const KEY_SKYBOX_SPACE_STATE = 'skyboxSpaceState' + space?.environment?.idName ?? 'unknown';
        let newSb: SkyBox | null = null;
        if (
            skyBoxArray.find((skybox) => {
                if (skybox.url === sbUrl || skybox.jpgUrl === jpgUrl) {
                    return true;
                }
            })
        ) {
            const activeSkyboxIndexTemp = skyBoxArray.findIndex((skybox) => {
                if (skybox.isActive === true) {
                    return skybox;
                }
            });
            console.log(
                'MEET:: setSkyBoxInSpaceState: found matching skybox in skyboxArray: activeSkyboxIndexTemp: ',
                activeSkyboxIndexTemp
            );
            if (activeSkyboxIndexTemp != -1) {
                skyBoxArray[activeSkyboxIndexTemp].isActive = false;
            }
            skyBoxArray.forEach((skybox) => {
                if (skybox.isActive) {
                    skybox.isActive = false;
                }

                if ((sbUrl && skybox.url === sbUrl) || (jpgUrl && skybox.jpgUrl === jpgUrl)) {
                    skybox.isActive = true;
                }
            });
            if (nSpaceState[KEY_SKYBOX_SPACE_STATE]) {
                nSpaceState[KEY_SKYBOX_SPACE_STATE].value.skyboxArray = skyBoxArray;
            } else {
                nSpaceState[KEY_SKYBOX_SPACE_STATE] = { value: { skyboxArray: skyBoxArray } };
            }
        } else {
            const tempDefaultSkyboxArray: Array<SkyBox> = [];

            defaultSkyBoxArray.forEach((skybox) => {
                newSb = {
                    filetype: 'env',
                    isActive: false,
                    isDefault: true,
                    name: skybox.name,
                    url: skybox.envName,
                    jpgUrl: skybox.jpgUrl
                };
                tempDefaultSkyboxArray.push(newSb);
            });

            newSb = {
                filetype: 'env',
                isActive: true,
                isDefault: false,
                name: prompt.replace(/[^a-zA-Z0-9]/g, '_'),
                url: sbUrl ?? undefined,
                jpgUrl: jpgUrl,
                urlMediumRes: urlMediumRes,
                urlHighRes: urlHighRes
            };
            tempDefaultSkyboxArray.push(newSb);
            if (nSpaceState[KEY_SKYBOX_SPACE_STATE]?.value?.skyboxArray) {
                nSpaceState[KEY_SKYBOX_SPACE_STATE].value.skyboxArray = spaceState[
                    KEY_SKYBOX_SPACE_STATE
                ].value.skyboxArray
                    .map((sb: { [key: string]: any }) => {
                        sb.isActive = false;
                        return { ...sb, isActive: false };
                    })
                    .concat(newSb);

                setSkyBoxArray(nSpaceState[KEY_SKYBOX_SPACE_STATE].value.skyboxArray);
            } else {
                nSpaceState[KEY_SKYBOX_SPACE_STATE] = { value: { skyboxArray: tempDefaultSkyboxArray } };
                setSkyBoxArray(nSpaceState[KEY_SKYBOX_SPACE_STATE].value.skyboxArray);
            }
        }
        console.log(
            'MEET:: setSkyBoxInSpaceState: nSpaceState[KEY_SKYBOX_SPACE_STATE].value: ',
            nSpaceState[KEY_SKYBOX_SPACE_STATE].value
        );
        setSpaceStateRemote(KEY_SKYBOX_SPACE_STATE, nSpaceState[KEY_SKYBOX_SPACE_STATE].value).then(
            async () => {
                await getApi(`/space/${space.id}/state`)
                    .then(async (r) => {
                        const json = await r.json();
                        if (r.ok && json) await setSpaceState(json);
                        console.log('MEET: setSkyBoxInSpaceState: Json after skybox space state update', json);
                        return true;
                    })
                    .catch((e: Error) => {
                        console.log('MEET: setSkyBoxInSpaceState: Could not get space stat with error: ', e);
                    });
            },
            (e: Error) => {
                console.log('MEET: setSkyBoxInSpaceState: Could not set space state with error: ', e);
            }
        );
    };

    const canBeManager = canCreateStation(currentUser) || isStationManager || hasHostedSpaces;
    const firebase = useFirebase();
    const dispatch = useDispatch();
    const handleSignOut = async () => {
        await firebase.logout().then(() => {
            dispatch(clearCurrentUser());
            history.push('/log-in');
        });
    };
    const auth = useSelector(({ firebase: { auth } }: RootState) => auth);

    let isLoggedIn = true;
    if (isAnon(currentUser)) {
        isLoggedIn = false;
    }
    const handleGetNowherePro = async () => {
        //  setUpgradePromptVisible(true);
        const successRoute =
            '/station/latest?fromPage=Meet&fromUserType=' + currentUser.userType + '&userId=' + currentUser.id;
        history.push('/pricing?' + successRoute);
    };
    const handleLoginClick = async () => {
        setLoginPromptVisible(true);
    };

    const handleLoginWithEmailClick = async () => {
        const baseUrl = document.location.origin;
        setLoginPromptVisible(false);
        //open link in same page
        window.open(baseUrl + '/sign-up', '_self');
    };

    // Render a canvas element and pass any additional props to it
    return (
        <Container>
            <Controls>
                <TopContainer>
                    <MeetNowhere isMobile={isMobile}>{'Meet Nowhere'}</MeetNowhere>
                    <MeetNowhereSub isMobile={isMobile}>AI generated worlds for every conversation</MeetNowhereSub>
                    {/*           {!isLoggedIn && (
                        <GoogleAuthButtonMeet>
                            <GoogleAuthButtonText>Continue with Google</GoogleAuthButtonText>
                        </GoogleAuthButtonMeet>
                    )} */}
                </TopContainer>
                <MeetDropdown
                    canBeManager={canBeManager}
                    isOpen={isMenuOpen}
                    setIsOpen={setIsMenuOpen}
                    handleSignOut={handleSignOut}
                    handleGetNowherePro={handleGetNowherePro}
                    isLoggedIn={isLoggedIn}
                    activateLogInModal={handleLoginClick}
                    canViewAccountUpsell={canViewAccountUpsell(currentUser)}
                ></MeetDropdown>
                {canViewAccountUpsell(currentUser) && isLoggedIn && (
                    <MeetGoPro handleGetNowherePro={handleGetNowherePro} isLoggedIn={isLoggedIn} />
                )}

                {loading && (
                    <LoadingBackground preLoading={preLoading}>
                        {!showErrorText && (
                            <img
                                src={Spinner}
                                style={{
                                    width: '30px',
                                    height: '30px'
                                }}
                            />
                        )}
                        {!showErrorText && <LoadingSkybox>Loading Meet...</LoadingSkybox>}
                        {showErrorText && <LoadingSkybox>Something went wrong. Reload to try again.</LoadingSkybox>}
                    </LoadingBackground>
                )}

                {loadingGen && (
                    <LoadingBackgroundGen preLoading={preLoadingGen}>
                        {!showErrorText && (
                            <img
                                src={Spinner}
                                style={{
                                    width: '30px',
                                    height: '30px'
                                }}
                            />
                        )}
                        {!showErrorText && <LoadingSkybox>Generating. This may take a minute.</LoadingSkybox>}
                        {showErrorText && (
                            <LoadingSkybox>
                                Something went wrong. Reload to try again or start a meet with a standard skybox.
                            </LoadingSkybox>
                        )}
                    </LoadingBackgroundGen>
                )}

                {loadingEnv && (
                    <LoadingBackgroundGen preLoading={preLoadingEnv}>
                        {!showErrorText && (
                            <img
                                src={Spinner}
                                style={{
                                    width: '30px',
                                    height: '30px'
                                }}
                            />
                        )}
                        {!showErrorText && <LoadingSkybox>Loading World...</LoadingSkybox>}
                        {showErrorText && <LoadingSkybox>Something went wrong. Reload to try again.</LoadingSkybox>}
                    </LoadingBackgroundGen>
                )}

                {loadingSkybox && (
                    <LoadingBackgroundGen preLoading={preLoadingSkybox}>
                        {!showErrorText && (
                            <img
                                src={Spinner}
                                style={{
                                    width: '30px',
                                    height: '30px'
                                }}
                            />
                        )}
                        {!showErrorText && <LoadingSkybox>Loading Skybox...</LoadingSkybox>}
                        {showErrorText && <LoadingSkybox>Something went wrong. Reload to try again.</LoadingSkybox>}
                    </LoadingBackgroundGen>
                )}

                {showSkyboxLoadingError && (
                    <SkyboxErrorModal isMobile={isMobile}>
                        <ErrorLoadingSkyboxText>Error Loading Skybox. Try a different one.</ErrorLoadingSkyboxText>
                    </SkyboxErrorModal>
                )}

                {upgradePromptVisible && (
                    <SubscriptionModal
                        handelClose={() => setUpgradePromptVisible(false)}
                        successRoute={
                            '/spaces#my-stations?fromPage=Meet&fromUserType=' +
                            currentUser.userType +
                            '&userId=' +
                            currentUser.id
                        }
                    />

                    /*                     <Modal isMobile={isMobile}>
                        <CloseButtonModal onClick={() => setUpgradePromptVisible(false)}></CloseButtonModal>
                        <NowhereProContainer isMobile={isMobile}>
                            <MeetLikeAPro isMobile={isMobile}>UP YOUR GAME</MeetLikeAPro>

                            <NowherePros isMobile={isMobile}>- Unlimited meeting time and more Spaces</NowherePros>
                            <NowherePros isMobile={isMobile}>- Access suite of admin controls</NowherePros>
                            <NowherePros isMobile={isMobile}>
                                - More world options (Auditorium, Office, Galleries, The Base)
                            </NowherePros>
                            <NowherePros isMobile={isMobile}>- Capacity up to 150 per space</NowherePros>
                            <NowherePros isMobile={isMobile}>- HLS Live Stream </NowherePros>
                            <NowherePros isMobile={isMobile}>- And much more!</NowherePros>

                            <UpgradeToNowherePro
                                onClick={() => {
                                    window.open('https://www.nowhere.io/nowhere-pro');
                                }}
                                isMobile={isMobile}
                            >
                                UPGRADE TO NOWHERE PRO
                            </UpgradeToNowherePro>
                        </NowhereProContainer>
                    </Modal> */
                )}

                {loginPromptVisible && (
                    <Modal isMobile={isMobile}>
                        <CloseButtonModal onClick={() => setLoginPromptVisible(false)}></CloseButtonModal>
                        <LoginContainer isMobile={isMobile}>
                            <SignUpOrLogIn isMobile={isMobile}>Please Log-in</SignUpOrLogIn>
                            <SignUpSubTitle isMobile={isMobile}>To your NOWHERE spatial experience.</SignUpSubTitle>
                            <BlueRectangle isMobile={isMobile}>
                                <GoogleAuthButtonMeet>
                                    <GoogleAuthButtonText>Continue with Google</GoogleAuthButtonText>
                                </GoogleAuthButtonMeet>
                            </BlueRectangle>
                            <ContinueAsGuest onClick={handleLoginWithEmailClick}>CONTINUE WITH EMAIL</ContinueAsGuest>
                            <PrivacyPolicy isMobile={isMobile}>
                                By continuing, you accept our{' '}
                                <Link href={'https://www.nowhere.io/terms-of-service'} target="_top">
                                    terms & privacy policy
                                </Link>
                            </PrivacyPolicy>
                        </LoginContainer>
                    </Modal>
                )}

                {loginPromptVisible && <FrostBackground></FrostBackground>}
                {/*    {upgradePromptVisible && <FrostBackground></FrostBackground>} */}
                <BottomContainer isMobile={isMobile}>
                    {/*       {showLoginSmallBox && (
                        <LoginSmallBox isMobile={isMobile}>
                            <FlexRowCloseButtonFlexStart>
                                <LogInTextSmallBox isMobile={isMobile}>
                                    {' '}
                                    Log-in or Sign-up to Unlock more worlds.
                                </LogInTextSmallBox>
                                <CloseButton
                                    isMobile={isMobile}
                                    onClick={() => setShowLoginSmallBox(false)}
                                ></CloseButton>
                            </FlexRowCloseButtonFlexStart>
                            <GoogleAuthButtonDummy
                                onClick={() => {
                                    setLoginPromptVisible(true);
                                }}
                            >
                                <div
                                    style={{
                                        fontFamily: 'anisette-std',
                                        fontSize: '20px',
                                        fontWeight: 'bold',
                                        textTransform: 'none',
                                        letterSpacing: '1.43px'
                                    }}
                                >
                                    Continue With Google
                                </div>
                            </GoogleAuthButtonDummy>
                        </LoginSmallBox>
                    )}
                    {showUpgradeSmallBox && (
                        <UpgradeSmallBox isMobile={isMobile}>
                            <FlexRowCloseButton>
                                <UpgradeTextSmallBox isMobile={isMobile}>
                                    {' '}
                                    More Worlds ~ More Options.
                                </UpgradeTextSmallBox>
                                <CloseButton
                                    isMobile={isMobile}
                                    onClick={() => setShowUpgradeSmallBox(false)}
                                ></CloseButton>
                            </FlexRowCloseButton>
                            <GoNowhereProGreen
                                type="button"
                                onClick={async () => {
                                    console.log('Open nowhere pro link here');
                                    setUpgradePromptVisible(true);
                                }}
                                value="GO NOWHERE PRO"
                                style={{ color: 'black', margin: '5px' }}
                            ></GoNowhereProGreen>
                        </UpgradeSmallBox>
                    )} */}
                    {copied && (
                        <CopiedBox>
                            <FlexRowCloseButtonFlexStart>
                                <LinkCopied>
                                    {' '}
                                    Link Copied! Add to your Calendar to meet later or share now for guests to jump in.
                                </LinkCopied>
                                <CloseButton isMobile={isMobile} onClick={() => setCopied(false)}></CloseButton>
                            </FlexRowCloseButtonFlexStart>
                            <InviteLinkSpan>{inviteLink}</InviteLinkSpan>
                        </CopiedBox>
                    )}

                    <SkyboxRow className="skybox-row" isMobile={isMobile}>
                        {!isMobile && (
                            <SettingsRow isOpened={isSettingsRowOpened}>
                                <SettingsColumn isOpen={isSettingsRowOpened}>
                                    <RowSettings>
                                        <FlexColumn>
                                            <SettingsSubheader>Choose World</SettingsSubheader>
                                            <SkyboxSelect
                                                className="skybox-style-select noTranslate"
                                                value={environmentId}
                                                onChange={setEnvironmentHandler}
                                                onFocus={pauseAnimationHandler}
                                                onBlur={startAnimationHandler}
                                                disabled={loadingEnv || loadingSkybox}
                                            >
                                                {defaultMeetEnvironments.map((option, index) => (
                                                    <Option key={index} value={option.environmentId}>
                                                        {option.environmentName}
                                                    </Option>
                                                ))}
                                                <Option key={'Custom'} value={'Custom'}>
                                                    {'More options...'}
                                                </Option>
                                            </SkyboxSelect>
                                        </FlexColumn>
                                        <FlexColumn>
                                            <SettingsSubheader>Choose Skybox</SettingsSubheader>
                                            <SkyboxSelect
                                                className="skybox-style-select noTranslate"
                                                value={skyboxUrl}
                                                onChange={setSkyboxHandler}
                                                onFocus={pauseAnimationHandler}
                                                onBlur={startAnimationHandler}
                                                disabled={loadingEnv || loadingSkybox}
                                            >
                                                {skyBoxArray.map((option, index) => (
                                                    <Option key={index} value={option.url ?? option.jpgUrl}>
                                                        {option.name}
                                                    </Option>
                                                ))}
                                            </SkyboxSelect>
                                        </FlexColumn>
                                        <FlexColumn>
                                            <SettingsSubheader>Name Your Meet</SettingsSubheader>
                                            <NameWorld
                                                isMobile={isMobile}
                                                className="noTranslate"
                                                isSettingsOpen={isSettingsRowOpened}
                                                type="text"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    setWorldName(e.target.value);
                                                }}
                                                value={worldName}
                                                placeholder="Set World Name"
                                                style={{ color: 'white', margin: '5px' }}
                                            ></NameWorld>
                                        </FlexColumn>
                                    </RowSettings>
                                </SettingsColumn>
                            </SettingsRow>
                        )}

                        {!generatingSkybox && (
                            <MenuFlexColumn isMobile={isMobile} isSettingsOpen={isSettingsRowOpened}>
                                <RowGenerateSkybox>
                                    <SettingsButton
                                        isMobile={isMobile}
                                        isOpen={isSettingsRowOpened}
                                        onClick={() => {
                                            setIsSettingsRowOpened(!isSettingsRowOpened);
                                            //  setShowUpgradeSmallBox(false);
                                            setShowLoginSmallBox(false);
                                            setCopied(false);
                                        }}
                                    ></SettingsButton>
                                    <Prompt
                                        className="noTranslate"
                                        type="text"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setPrompt(e.target.value);
                                        }}
                                        style={{ color: 'white', margin: '5px' }}
                                        placeholder="Type a prompt to generate your skybox"
                                        value={prompt}
                                        maxLength={skyboxGenType['max-char']}
                                    />

                                    <SelectRightMargin
                                        className="skybox-style-select noTranslate"
                                        defaultValue={skyboxGenType.id}
                                        onChange={setSkyboxGenTypeChangeHandler}
                                    >
                                        {skyboxGenTypes.map((option, index) => (
                                            <Option key={index} value={option.id}>
                                                {option.name}
                                            </Option>
                                        ))}
                                    </SelectRightMargin>
                                    <Confirm
                                        type="button"
                                        onClick={() => {
                                            setLoadingGen(true);
                                            setPreLoadingGen(true);
                                            genSkybox(prompt);
                                        }}
                                        value="Generate"
                                        style={{ color: 'white' }}
                                        disabled={!prompt}
                                    ></Confirm>
                                </RowGenerateSkybox>
                            </MenuFlexColumn>
                        )}
                        {generatingSkybox && (
                            <GeneratingSkybox isMobile={isMobile}>
                                <img
                                    src={Spinner}
                                    style={{
                                        width: '30px',
                                        height: '30px',
                                        marginRight: '10px',
                                        marginLeft: '30px'
                                    }}
                                />
                                <Generating>Generating...</Generating>
                                <VerticleRule></VerticleRule>
                                <FlexColumnGenerating>
                                    <GenerationStepText>{skyboxProgressString}</GenerationStepText>
                                    <PromptInfoText>
                                        {skyboxGenTypes.find((skyboxStyle) => skyboxStyle.id == skyboxGenType.id)?.name ?? 'Processing prompt...'}
                                    </PromptInfoText>
                                </FlexColumnGenerating>
                                <CloseButtonGenerating
                                    isMobile={isMobile}
                                    onClick={() => {
                                        setPreLoadingGen(false);
                                        setTimeout(() => {
                                            setLoadingGen(false);
                                        }, 1000);
                                        startAnimationHandler();
                                        setGeneratingSkybox(false);
                                        setSbGenResults(null);
                                    }}
                                ></CloseButtonGenerating>
                            </GeneratingSkybox>
                        )}

                        <FlexColumnStartMeet isMobile={isMobile} isOpened={isSettingsRowOpened}>
                            {isMobile && (
                                <SettingsButton
                                    isMobile={isMobile}
                                    isOpen={isSettingsRowOpened}
                                    onClick={() => setIsSettingsRowOpened(!isSettingsRowOpened)}
                                ></SettingsButton>
                            )}
                            <StartMeet
                                type="button"
                                onClick={async () => {
                                    if (isAnon(currentUser)) {
                                        setLoginPromptVisible(true);
                                    } else {
                                        const data = { title: worldName };
                                        console.log(data);
                                        await setSpaceRemote(data);
                                        if (window.top) {
                                            window.top.location.href = inviteLink;
                                        }
                                    }
                                }}
                                value="Start Meet"
                                style={{ color: 'black' }}
                            ></StartMeet>
                            <CopiedContainer>
                                {isMobile && (
                                    <CopiedButtonMobile
                                        type="button"
                                        onClick={() => {
                                            if (!isLoggedIn) {
                                                //open pro
                                                setLoginPromptVisible(true);
                                                return;
                                            }

                                            // Copy the text inside the text field
                                            navigator.clipboard.writeText(inviteLink);
                                            setCopied(true);
                                            setIsSettingsRowOpened(false);
                                            console.log('Invite Link Copied!', inviteLink);
                                            setInviteCopiedText('Copied!');
                                            setIsSettingsRowOpened(false);
                                            setTimeout(() => {
                                                setInviteCopiedText('Invite');
                                            }, 3000);
                                        }}
                                        value={inviteCopiedText}
                                    ></CopiedButtonMobile>
                                )}
                                {!isMobile && (
                                    <CopiedButton
                                        type="button"
                                        onClick={() => {
                                            if (!isLoggedIn) {
                                                //open pro
                                                setLoginPromptVisible(true);
                                                return;
                                            }
                                            // Copy the text inside the text field
                                            navigator.clipboard.writeText(inviteLink);
                                            setCopied(true);
                                            setIsSettingsRowOpened(false);
                                            console.log('Invite Link Copied!', inviteLink);
                                            setInviteCopiedText('Copied!');
                                            setIsSettingsRowOpened(false);
                                            setTimeout(() => {
                                                setInviteCopiedText('Invite');
                                            }, 3000);
                                        }}
                                        value={inviteCopiedText}
                                    >
                                        INVITE
                                    </CopiedButton>
                                )}
                                <CopiedRectangle>{inviteCopiedText}</CopiedRectangle>
                            </CopiedContainer>
                        </FlexColumnStartMeet>
                        {isMobile && (
                            <SettingsRowMobile isOpened={isSettingsRowOpened}>
                                <SettingsColumnMobile isOpen={isSettingsRowOpened}>
                                    <RowSettings>
                                        <FlexColumnMobile isSettingsOpen={isSettingsRowOpened}>
                                            <SettingsSubheader>Name Your Meet</SettingsSubheader>
                                            <NameWorldMobile
                                                isMobile={isMobile}
                                                className="noTranslate"
                                                isSettingsOpen={isSettingsRowOpened}
                                                type="text"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    setWorldName(e.target.value);
                                                }}
                                                value={worldName}
                                                placeholder="Set World Name"
                                                style={{ color: 'white', margin: '5px' }}
                                            ></NameWorldMobile>
                                        </FlexColumnMobile>
                                        <FlexColumnMobile isSettingsOpen={isSettingsRowOpened}>
                                            <SettingsSubheader>Choose World</SettingsSubheader>
                                            <SkyboxSelectMobile
                                                className="skybox-style-select noTranslate"
                                                value={environmentId}
                                                onChange={setEnvironmentHandler}
                                                disabled={loadingEnv || loadingSkybox}
                                            >
                                                {defaultMeetEnvironments.map((option, index) => (
                                                    <Option key={index} value={option.environmentId}>
                                                        {option.environmentName}
                                                    </Option>
                                                ))}
                                                <Option key={'Custom'} value={'Custom'}>
                                                    {'More options...'}
                                                </Option>
                                            </SkyboxSelectMobile>
                                        </FlexColumnMobile>
                                        <FlexColumnMobileSkybox isSettingsOpen={isSettingsRowOpened}>
                                            <SettingsSubheader>Choose Skybox</SettingsSubheader>
                                            <SkyboxSelectMobile
                                                className="skybox-style-select noTranslate"
                                                value={skyboxUrl}
                                                onChange={setSkyboxHandler}
                                                disabled={loadingEnv || loadingSkybox}
                                            >
                                                {skyBoxArray.map((option, index) => (
                                                    <Option key={index} value={option.url}>
                                                        {option.name}
                                                    </Option>
                                                ))}
                                            </SkyboxSelectMobile>
                                            <FlexRow>
                                                <SkyboxImage></SkyboxImage>
                                                <MobileNotice>
                                                    {' '}
                                                    Switch to a computer to generate AI Skyboxes{' '}
                                                </MobileNotice>
                                            </FlexRow>
                                        </FlexColumnMobileSkybox>
                                    </RowSettings>
                                </SettingsColumnMobile>
                            </SettingsRowMobile>
                        )}
                    </SkyboxRow>
                </BottomContainer>
            </Controls>
            <Canvas>
                <canvas style={{ width: '100vw', height: '100dvh' }} ref={reactCanvas} {...rest} />
            </Canvas>
        </Container>
    );
};
const GoogleAuthButtonText = styled.div`
    font-family: anisette-std;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1.43px;
    text-transform: none;
    @media (max-width: 460px) {
        font-size: 15px;
        text-align: center;
    }
    @media (max-width: 340px) {
        font-size: 10px;
        text-align: center;
    }
`;
const Link = styled.a`
    text-decoration: underline !important;
`;

const SkyboxImage = styled.div`
margin-top: 10px;
margin-right: 10px;
    min-width: 16px;
    min-height: 16px;
    width: 16px;
    height: 16px;
    border-radius: 5px;
    background: url(${IconSkybox}) center no-repeat transparent;

  }

`;
const MobileNotice = styled.div`
    margin-top: 10px;
    font-family: anisette-petite;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #fff;
`;
const PromptInfoText = styled.div`
    opacity: 0.75;
    font-family: anisette-petite;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.81px;
    color: var(--4-white);
`;

const GenerationStepText = styled.div`
    font-family: anisette-std;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.88px;
    color: #bee639;
`;

const Generating = styled.span`
    width: 150px;
    margin: 10.6px 7.5px 12.4px 10px;
    font-family: anisette-std;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.43px;
    text-align: center;
    color: #bee639;
`;
const VerticleRule = styled.div`
    width: 1px;
    height: 50px;
    margin: 0 20px 0 7.5px;
    border: solid 1px #bee639;
`;
const Option = styled.option`
    background-color: #343550;
`;

const UpgradeTextSmallBox = styled.span<{ isMobile: boolean }>`
    font-family: anisette-std;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.4px;
    color: #fff;

    ${({ isMobile }) =>
        isMobile
            ? '    font-size: 10px; white-space: nowrap;   margin-left: 25px;     margin-right: 25px;'
            : '     font-size: 16px;     margin-left: 75px;     margin-right: 75px;'};
`;
const LogInTextSmallBox = styled.span<{ isMobile: boolean }>`
    font-family: anisette-std;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    margin-right: 20px;
    margin-bottom: 10px;
    margin-left: 20px;
    letter-spacing: 0.4px;
    color: #fff;
    ${({ isMobile }) =>
        isMobile ? '    font-size: 10px; white-space: nowrap;   margin-left: 25px;     margin-right: 25px;' : '     '};
`;
const LinkCopied = styled.span`
    width: 438px;
    height: 40px;

    font-family: anisette-petite;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.4px;
    color: #fff;
    @media (max-width: 960px) {
        justify-content: center;
        width: 85vw;
        font-size: 12px;
    }
`;

const InviteLinkSpan = styled.span`
    width: 477px;
    height: 24px;

    font-family: anisette-petite;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.35px;
    color: #fff;
    @media (max-width: 960px) {
        justify-content: center;
        width: 90vw;
        font-size: 10px;
    }
    pointer-events: auto;
`;

const SettingsSubheader = styled.div`
    font-family: all-round-gothic;
    white-space: nowrap;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: #fbfbfb;
    font-size: 14px;
    margin-bottom: 5px;
    letter-spacing: 1.08px;
    align-self: left;
    @media (max-width: 675px) {
        margin-top: 10px;
    }
`;

const SignUpSubTitle = styled.div<{ isMobile: boolean }>`
    height: 17px;
    font-family: all-round-gothic;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;
    letter-spacing: 1.17px;
    text-align: center;
    color: #fff;
    @media (max-width: 960px) {
        font-size: 14px;
    }
    ${({ isMobile }) => (isMobile ? 'font-size: 10px;' : ' font-size: 14px;')};
`;
const ContinueAsGuest = styled.div`
    width: 177px;
    height: 19px;
    text-decoration: underline;
    font-family: all-round-gothic;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.14px;
    pointer-events: auto;
    cursor: pointer;
    white-space: nowrap;
`;

const PrivacyPolicy = styled.div<{ isMobile: boolean }>`
    width: 500px;

    color: #fff;
    bottom: 30px;
    margin-top: 40px;
    font-family: all-round-gothic;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
    text-align: center;
    pointer-events: auto;
    @media (max-width: 960px) and screen and (orientation: portrait) {
        font-size: 2vw;
    }
    @media (max-width: 960px) and screen and (orientation: landscape) {
        font-size: 2vw;
        margin-top: 20px;
    }
    ${({ isMobile }) =>
        isMobile ? 'font-size: 10px;  width: 70vw;' : ' font-size: 16px;  width: 500px;  height: 24px;'};
`;

const SignUpOrLogIn = styled.div<{ isMobile: boolean }>`
    margin-top: 20px;
    margin-bottom: 17px;
    font-family: anisette-std;
    font-size: 60px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
    text-align: center;
    color: #fff;

    @media screen and (orientation: landscape) and (max-width: 960px) {
        margin-top: 0px;
        font-size: 40px;
    }

    @media (max-width: 960px) {
        font-size: 30px;
    }
`;
const MeetLikeAPro = styled.div<{ isMobile: boolean }>`
    margin-bottom: 30px;

    font-family: anisette-std;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    @media (max-width: 960px) {
        font-size: 4vw;
        margin-bottom: 20px;
        margin-top: 5px;
    }
`;

const NowhereProContainer = styled.div<{ isMobile: boolean }>`
    @media (max-width: 960px) {
        width: 60vw;
    }
    ${({ isMobile }) => (isMobile ? '  width: 60vw;      ' : ' width: 648px;  height: 159px; ')};
`;

const LoginContainer = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;
    max-width: 768px;
`;

const NowherePros = styled.div<{ isMobile: boolean }>`
    font-family: anisette-petite;

    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    margin-bottom: 10px;
    letter-spacing: 0.4px;
    color: #fff;
    @media (max-width: 960px) {
        margin-bottom: 2vh;
        font-size: 2vw;
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    pointer-events: none;
    overflow: hidden;
`;

const Canvas = styled.div`
    width: 100vw;
    height: 100dvh;
    pointer-events: auto;
`;
const Controls = styled.div`
    justify-content: center;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    pointer-events: none;
    height: 100dvh;
    width: 100vw;
`;

const BottomContainer = styled.div<{ isMobile: boolean }>`
    bottom: 20px;
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    ${({ isMobile }) => (isMobile ? 'width: 100vw; ' : '')};
`;
const TopContainer = styled.div`
    top: 80px;
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    z-index: 1;
`;
const SkyboxRow = styled.div<{ isMobile: boolean }>`
    align-items: center;

    display: flex;
    flex-direction: row;
    pointer-events: auto;
    border-radius: 10px;
    font-family: anisette-std;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.43px;
    text-align: center;
    color: #010027;
    max-width: 100%;
    flex-wrap: wrap;

    ${({ isMobile }) => (isMobile ? 'padding: 0 20px 0 20px;' : 'padding: 20px 21px 20px 20px;')};
`;

const SettingsColumn = styled.div<{ isOpen: boolean }>`
    flex-direction: column;
    align-items: flex-start;
    background-color: rgba(52, 53, 80, 0.5);
    padding: 10px;
    max-width: 100vw;
    transition: all 0.3s ease-out; /* Add a transition effect */

    ${({ isOpen }) => (isOpen ? '     border-radius: 10px 10px 0 0;' : '    border-radius: 0 0 0 0;')};
`;
const SettingsColumnMobile = styled.div<{ isOpen: boolean }>`
    flex-direction: column;
    align-items: flex-start;
    background-color: rgba(52, 53, 80, 0.5);
    @media screen and (orientation: portrait) {
        padding: 10px;
    }
    @media screen and (orientation: landscape) {
        ${({ isOpen }) => (isOpen ? '       padding-top: 10px;' : '   ')};
    }
    max-width: 100vw;
    border-radius: 0 0 10px 10px;
    transition: all 0.3s ease-out; /* Add a transition effect */
`;

const SettingsRow = styled.div<{ isOpened: boolean }>`
    align-items: center;
    display: flex;

    flex-direction: row;
    position: absolute;
    z-index: 2;
    font-family: anisette-std;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.43px;
    text-align: center;
    transition: all 0.3s ease-out; /* Add a transition effect */
    max-width: 95vw;
    @media (max-width: 960px) {
        ${({ isOpened }) => (isOpened ? 'bottom: 140px;' : 'bottom: 20px; opacity: 0; ')};
        ${({ isOpened }) => (isOpened ? ' pointer-events: auto;' : ' pointer-events: none;')};
    }
    ${({ isOpened }) => (isOpened ? 'bottom: 80px;' : 'bottom: 20px; opacity: 0; ')};
    ${({ isOpened }) => (isOpened ? ' pointer-events: auto;' : ' pointer-events: none;')};
`;

const SettingsRowMobile = styled.div<{ isOpened: boolean }>`
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    z-index: 2;
    font-family: anisette-std;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.43px;
    text-align: center;
    transition: all 0.3s ease-out; /* Add a transition effect */
`;
const StartMeetRow = styled.div<{ isMobile: boolean }>`
    padding: 15px 55px 75px 55px;
    opacity: 0.75;
    border-radius: 0 0 10px 10px;
    box-shadow: 2px 5px 24px 2px rgba(0, 0, 0, 0.5);
    background-color: #030217;
    margin-bottom: 10px;

    ${({ isMobile }) =>
        isMobile
            ? 'width: 80vw;     height: 125px; align-items: center; display: flex; flex-direction: column;'
            : ' width: 500px;     height: 75px;'};
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
const FlexColumnMobileSkybox = styled.div<{ isSettingsOpen: boolean }>`
    display: flex;
    flex-direction: column;
    margin-bottom: ${({ isSettingsOpen }) => (isSettingsOpen ? '10px' : '0')};
    overflow: hidden;
    @media screen and (orientation: landscape) {
        max-height: ${({ isSettingsOpen }) => (isSettingsOpen ? '100px' : '0')};
    }

    transition: all 0.3s ease-out; /* Add a transition effect */
    align-items: flex-start;
`;

const FlexColumnMobile = styled.div<{ isSettingsOpen: boolean }>`
    display: flex;
    flex-direction: column;
    margin-bottom: ${({ isSettingsOpen }) => (isSettingsOpen ? '10px' : '0')};
    overflow: hidden;
    max-height: ${({ isSettingsOpen }) => (isSettingsOpen ? '100px' : '0')};
    transition: all 0.3s ease-out; /* Add a transition effect */
    align-items: flex-start;
`;
const FlexColumnGenerating = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 30px;
`;
const MenuFlexColumn = styled.div<{ isMobile: boolean; isSettingsOpen: boolean }>`
    flex-direction: column;
    align-items: flex-start;
    background-color: rgba(3, 2, 23, 0.75);
    padding: 10px;
    z-index: 3;

    @media (max-width: 960px) {
        width: 95vw;
        border-radius: 10px 10px 0 0;

        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        ${({ isMobile }) => (isMobile ? ' display: none;' : ' display: flex;')};
        ${({ isSettingsOpen }) =>
            isSettingsOpen ? '     border-radius: 0 10px 0 0;' : '     border-radius: 10px 10px 0 0;'};
    }
    transition: border-radius 0.3s ease-out; /* Add a transition effect */
    ${({ isMobile }) => (isMobile ? ' display: none;' : ' display: flex;')};
    ${({ isSettingsOpen }) =>
        isSettingsOpen ? '     border-radius: 0 0 0 10px;' : '    border-radius: 10px 0 0 10px;'};
`;

const FlexColumnStartMeet = styled.div<{ isMobile: boolean; isOpened: boolean }>`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-content: center;
    flex-direction: row;
    z-index: 15;
    background-color: #343550;
    padding: 10px;
    border-radius: 0 10px 10px 0;
    background-image: linear-gradient(255deg, #3e3adf, #448bc4);
    box-shadow: 2px 5px 24px 2px rgba(0, 0, 0, 0.5);
    justify-content: center;

    @media (max-width: 960px) {
        width: 100%;
        @media screen and (orientation: landscape) {
            ${({ isMobile, isOpened }) =>
                isMobile && isOpened
                    ? '   border-radius: 10px 10px 0px 0px;'
                    : isMobile
                    ? '   border-radius: 10px 10px 10px 10px;'
                    : '     border-radius: 0 0 10px 10px;'};
        }
        @media screen and (orientation: portrait) {
            ${({ isMobile }) => (isMobile ? '   border-radius: 10px 10px 0 0;' : '     border-radius: 0 0 10px 10px;')};
        }
    }
    ${({ isMobile }) =>
        isMobile ? 'width: 100vw;     border-radius: 10px 10px 0 0;' : '     border-radius: 0 10px 10px 0;'};
`;
const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;
const FlexRowCloseButton = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: center;
`;
const FlexRowCloseButtonFlexStart = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: flex-start;
`;
const RowGenerateSkybox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 960px) {
        justify-content: center;
        width: 90vw;
    }
    @media (max-width: 450px) {
        justify-content: center;
    }
`;
const RowSettings = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`;
const CycleRow = styled.div<{ isMobile: boolean }>`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    display: flex;
    height: 32px;
    padding: 6px 10px 7px;
    opacity: 0.75;
    border-radius: 10px 10px 0 0;
    box-shadow: 2px 5px 24px 2px rgba(0, 0, 0, 0.5);
    background-color: #343550;
    ${({ isMobile }) => (isMobile ? 'width: 80vw;' : ' width: 500px;')};
`;

const CycleRowLoading = styled.div<{ isMobile: boolean }>`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    display: flex;
    height: 32px;
    padding: 6px 10px 7px;
    opacity: 0.75;
    border-radius: 10px 10px 0 0;
    box-shadow: 2px 5px 24px 2px rgba(0, 0, 0, 0.5);
    background-color: #343550;
    justify-content: center;
    ${({ isMobile }) => (isMobile ? 'width: 80vw;  ' : '     width: 500px;')};
`;

const CopiedBox = styled.div`
    width: 500px;
    padding: 18px 20px 17px;
    border-radius: 10px;
    box-shadow: 2px 5px 24px 2px rgba(0, 0, 0, 0.5);
    background-color: #030217;
    margin-bottom: 10px;
    opacity: 75%;
    height: 130px;
    display: flex;
    flex-direction: column;
    @media (max-width: 960px) {
        justify-content: center;
        width: 90vw;
    }
`;
const UpgradeSmallBox = styled.div<{ isMobile: boolean }>`
    padding: 18px 20px 17px;
    border-radius: 10px;
    box-shadow: 2px 5px 24px 2px rgba(0, 0, 0, 0.5);
    background-color: #030217;
    margin-bottom: 10px;
    opacity: 75%;
    height: 130px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;

    ${({ isMobile }) => (isMobile ? 'width: 80vw;' : ' width: 500px;')};
`;

const FrostBackground = styled.div`
    width: 100%;
    height: 100%;
    background-color: #3e3adf;
    opacity: 75%;
    position: absolute;
    z-index: 998;
`;
const LoadingBackground = styled.div<{ preLoading: boolean }>`
    width: 100%;
    height: 100%;
    background-color: #3e3adf;
    opacity: 100%;
    position: absolute;
    z-index: 998;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ preLoading }) => (preLoading ? 'opacity: 100%' : 'opacity: 0')};
    transition: opacity 2s ease-out; /* Add a transition effect */
`;
const LoadingBackgroundGen = styled.div<{ preLoading: boolean }>`
    width: 100%;
    height: 100%;
    background-color: #3e3adf;
    opacity: 100%;
    position: absolute;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ preLoading }) => (preLoading ? 'opacity: 100%' : 'opacity: 0')};
    transition: opacity 2s ease-out; /* Add a transition effect */
`;
const Modal = styled.div<{ isMobile: boolean }>`
    pointer-events: auto;
    padding: 30px 0 23px;
    opacity: 0.9;
    border-radius: 15px;
    position: absolute;
    box-shadow: 1px 2px 30px 0 rgba(0, 0, 0, 0.5);
    background-color: #010027;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 25vh 25vw 25vh 25vw;
    @media (max-width: 960px) {
        height: fit-content;
        width: 80vw;
        margin: 10px;
    }
    @media screen and (orientation: portrait) {
        ${({ isMobile }) =>
            isMobile ? 'height: fit-content;  width: 80vw;    padding: 20px;     ' : ' max-width: 768px;  '};
    }
    @media screen and (orientation: landscape) {
        ${({ isMobile }) =>
            isMobile ? 'height: fit-content;  width: 80vw; padding: 20px;      ' : 'max-width: 768px; '};
    }
`;
const SkyboxErrorModal = styled.div<{ isMobile: boolean }>`
    padding: 30px 0 23px;
    opacity: 0.9;
    border-radius: 15px;
    position: absolute;
    box-shadow: 1px 2px 30px 0 rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(255deg, #3e3adf, #448bc4);
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30vh 25vw 25vh 25vw;
    @media (max-width: 960px) {
        width: 80vw;
        margin: 30vh 25vw 25vh 25vw;
    }
    ${({ isMobile }) => (isMobile ? ' width: 80vw;      ' : '   width: 768px;   ')};
`;

const BlueRectangle = styled.div<{ isMobile: boolean }>`
    height: 105px;
    margin: 26px 0 30px;
    padding: 30px;
    opacity: 0.9;
    background-color: #3e3adf;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 960px) {
        width: 80vw;
    }

    ${({ isMobile }) => (isMobile ? 'width: 80vw' : ' width: 768px')};
`;

const LoginSmallBox = styled.div<{ isMobile: boolean }>`
    width: 500px;
    padding: 18px 20px 17px;
    border-radius: 10px;
    box-shadow: 2px 5px 24px 2px rgba(0, 0, 0, 0.5);
    background-color: #030217;
    margin-bottom: 10px;
    opacity: 75%;
    height: 130px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    ${({ isMobile }) => (isMobile ? 'width: 80vw;' : ' width: 500px;')};
`;

const LoadingSkybox = styled.h1`
    margin-left: 20px;
    text-shadow: 2px 5px 24px rgba(0, 0, 0, 0.5);
    font-family: anisette-std;
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    pointer-events: none;
    z-index: 999;
`;

const GeneratingSkybox = styled.div<{ isMobile: boolean }>`
    color: white;
    background-color: rgba(3, 2, 23, 0.75);
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 10px 0 0 10px;
    z-index: 3;
    @media (max-width: 960px) {
        justify-content: center;
        width: 95vw;
    }
`;

const CloseButton = styled.button<{ isMobile: boolean }>`
    border: none;
    background: url(${IconClose}) center no-repeat transparent;
    cursor: pointer;
    height: 20px;
    pointer-events: auto;
    position: relative;
    ${({ isMobile }) => (isMobile ? 'margin-right: 10px;' : '')};
`;

const CloseButtonGenerating = styled.button<{ isMobile: boolean }>`
    border: none;
    background: url(${IconClose}) center no-repeat transparent;
    cursor: pointer;
    height: 20px;
    pointer-events: auto;
    position: relative;
    margin-right: 10px;
    ${({ isMobile }) => (isMobile ? 'margin-right: 10px;' : '')};
`;

const CloseButtonModal = styled.button`
    border: none;
    background: url(${IconClose}) center no-repeat transparent;
    cursor: pointer;
    height: 20px;
    pointer-events: auto;
    position: absolute;
    right: 30px;
`;

const MeetNowhere = styled.h1<{ isMobile: boolean }>`
    text-shadow: 2px 5px 24px rgba(0, 0, 0, 0.5);
    font-family: anisette-std;
    font-size: 120px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--4-white);
    text-align: center;
    pointer-events: none;
    @media (max-width: 960px) {
        font-size: 12vw;
    }

    @media screen and (orientation: landscape) and (max-width: 960px) {
        font-size: 8vw;
    }

    ${({ isMobile }) => (isMobile ? ' ' : ' ')};
`;

const MeetNowhereSub = styled.h2<{ isMobile: boolean }>`
    width: 80vw;
    text-shadow: 2px 5px 24px rgba(0, 0, 0, 0.5);
    font-family: all-round-gothic;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    pointer-events: none;
    margin-bottom: 20px;
    @media (max-width: 960px) {
        font-size: 3vw;
    }
    @media screen and (orientation: landscape) and (max-width: 960px) {
        font-size: 2vw;
    }

    ${({ isMobile }) => (isMobile ? ' ' : ' ')};
`;

const Prompt = styled.input`
    font-family: anisette-petite;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;

    text-align: left;
    background-color: transparent;
    border: solid 1px white !important;
    pointer-events: auto;

    width: 20vw;
    height: 40px;
    margin: 0 10px 0 0 !important;
    padding: 10px 0 10px 15px;
    border-radius: 5px;
    border: solid 1px var(--4-white);
    @media (max-width: 960px) {
        width: 100vw;
    }
    @media (max-width: 450px) {
        width: 25vw;
    }
`;
const Rule = styled.div`
    width: 420px;
    height: 2px;
    margin: 0 0 0 6px;
    border: solid 2px 
    align-self: center;
    color: white;
    background-color: white;
`;
const NameWorld = styled.input<{ isMobile: boolean; isSettingsOpen: boolean }>`
    font-family: anisette-petite, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: left;
    background-color: transparent;
    border: solid 1px white !important;
    pointer-events: auto;
    color: white;
    margin: 0 0 0 0 !important;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
`;
const NameWorldMobile = styled.input<{ isMobile: boolean; isSettingsOpen: boolean }>`
    font-family: anisette-petite, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: left;
    width: 80vw;
    background-color: transparent;
    border: solid 1px white !important;
    pointer-events: auto;
    color: white;
    margin: 0 10px 0 0 !important;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    transition: all 2s ease-out; /* Add a transition effect */
`;
const GoNowhereProGreen = styled.input`

height: 45px;
margin: 0 20px;
padding: 10px 20px 10px 60px;
border-radius: 23px;
background-color: #bee639;
font-family: all-round-gothic;
font-size: 20px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: 1.43px;
text-align: center;
color: #010027;
pointer-events: auto;
background-image: url(${IconPro}) ;
background-repeat: no-repeat;
background-position: 25px;
background-size: 20px;
}

`;

const StartMeet = styled.input`

height: 40px;
margin: 0 10px 0 5px;
padding: 9px 20px 10px 20px;
border-radius: 23px;
background-color: #bee639;
font-family: anisette-std;
font-size: 20px;
font-weight: 600;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: 1.43px;
text-align: center;
color: #010027;
pointer-events: auto;
z-index: 100;
 transition: all 0.3s ease-out; /* Add a transition effect */

&:hover {
    cursor: pointer;
    background-color: white;
}


}

`;

const UpgradeToNowherePro = styled.button<{ isMobile: boolean }>`
    height: 50px;
    width: 400px;

    margin-top: 20px;
    border-radius: 20px;
    background-color: #bee639;
    font-family: all-round-gothic;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    font-color: black;
    line-height: normal;
    letter-spacing: 1.43px;
    text-align: center;
    padding-left: 35px;
    color: #010027;
    background-image: url(${IconPro});
    background-repeat: no-repeat;
    background-position: 25px;
    background-size: 25px;
    pointer-events: auto;
    @media (max-width: 960px) {
        width: 60vw;
        font-size: 2.75vw;
        height: 5vh;
        background-size: 3vw;
        margin-top: 10px;
    }
`;

const Confirm = styled.input`
    font-family: anisette-std;
    font-size: 20px;
    height: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: .7px;
    text-align: center;

    padding: 6px 18px 6px;
    border-radius: 20px;
    border: solid 2px !important;
    pointer-events: auto;
    background-color: transparent;

    pointer-events: auto;
    @media (max-width: 675px) {
        font-size: 2.5vw;
    }

    &:disabled {
        opacity: 0.25;
      }

  }

`;

const SettingsButton = styled.button<{ isOpen: boolean; isMobile: boolean }>`
    min-width: 40px;
    width: 40px;
    height: 40px;
    padding: 10px 10px 10px 10px;
    border-radius: 5px;
  
    pointer-events: auto;
 

    background: url(${IconSettings}) center no-repeat transparent;
    ${({ isOpen }) =>
        isOpen ? '   background-color: black;    filter: invert(100%);  ' : '   background-color: transparent;'};
    ${({ isMobile }) => (isMobile ? '  ' : '     border: solid 1px white!important;  margin-right: 10px;')};
  }

`;

const CopiedRectangle = styled.div`
    width: 87px;
    height: 30px;
    padding: 6px 10px 7px;
    position: absolute;
    bottom: 0px;
    left: 85px;
    object-fit: contain;
    border-radius: 5px;
    box-shadow: 0 1px 6px 0 rgba(1, 0, 39, 0.5);
    background-color: #0a0a0d;
    justify-content: center;
    font-family: all-round-gothic;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f6f6f9;
    pointer-events: none;
    display: none; /* Hide the component by default */
`;

const CopiedButtonMobile = styled.button`
    min-width: 20px;
    width: 20px;
    padding-top: 6px;
    height: 20px;
    margin-right: 10px;
    pointer-events: auto;
    background-color: transparent;
    background: url(${IconCopy}) center no-repeat transparent;

    &:hover {
        cursor: pointer;
        background: url(${IconCopyHover}) center no-repeat transparent;
    }
`;

const CopiedButton = styled.button`
    height: 30px;
    background-color: white;
    margin-right: 5px;

    font-family: all-round-gothic;
    padding-left: 15px;
    padding-right: 15px;
    pointer-events: auto;
    letter-spacing: 1px;
    font-size: 14px;
    border-radius: 20px;
    &:hover {
        cursor: pointer;
        background-color: #bee639;
    }
    transition: all 0.3s ease-out; /* Add a transition effect */
`;
const CopiedContainer = styled.div`
    position: relative;
    z-index: 100;
    display: flex;
    &:hover ${CopiedRectangle} {
        display: flex;
    }
`;

const SkyboxSelect = styled.select`
    flex-shrink: 0;
    height: 1.85rem;
    font-size: 0.75rem;
    font-family: anisette-petite, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    background-color: transparent;
    border: solid 1px white !important;
    width: 220px;
    height: 40px;
    margin: 0 10px 0 0;
    padding: 9.9px 15px 11.1px;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(28, 2, 33, 0.75);
    border: solid 1px var(--4-white);

    pointer-events: auto;
`;
const SkyboxSelectMobile = styled.select`
    flex-shrink: 0;
    height: 1.85rem;
    font-size: 0.75rem;
    font-family: anisette-petite, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    width: 80vw;
    background-color: transparent;
    border: solid 1px white !important;
    height: 40px;
    margin: 0 10px 0 0;
    padding: 9.9px 15px 11.1px;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(28, 2, 33, 0.75);
    border: solid 1px var(--4-white);
    pointer-events: auto;
`;
const SelectRightMargin = styled.select`
    flex-shrink: 0;
    height: 1.85rem;
    font-size: 0.75rem;
    font-family: anisette-petite;
    color: white;
    background-color: transparent;
    border: solid 1px white !important;
    max-width: 220px;
    height: 40px;
    margin: 0 10px 0 0;
    padding: 9.9px 15px 11.1px;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(28, 2, 33, 0.75);
    border: solid 1px var(--4-white);

    pointer-events: auto;

    @media (max-width: 960px) {
        width: 20vw;
    }
`;
const ErrorLoadingSkyboxText = styled.div`
    margin: 30px;
    font-family: anisette-std;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.43px;
    text-align: center;
    color: #fff;
    pointer-events: none;
`;
export default Meet;
