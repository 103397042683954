import * as React from 'react';
import { Modal } from '../layouts/Modal';
import { useHistory } from 'react-router-dom';
import { ApiSpace } from '../../api/ApiTypes';
import Message, { MessageButtonContainer } from '../layouts/Message';
import { SmallButton, LargeButton } from '../inputs/Button';
import { postApi, putApi, getApi } from '../../api/api';
import { useState } from 'react';
import SpotifyAuthButton from '../inputs/SpotifyAuthButton';
import styled, {keyframes} from 'styled-components/macro';
import WelcomeBG from '../../images/welcome-bg.jpg';

const LitJsSdk = require('lit-js-sdk');

const stationRedirectRoute  = async (stationId?: string): Promise<string> => {
    if(!stationId) {
        return '/'
    } else {
        return await getApi(`/station/${stationId}`).then(() =>
            `/station/${stationId}`
        ).catch(() =>
            '/'
        );
    }
};


export const ErrorModal = () => {
    const history = useHistory();
    const goToHome = () => history.push('/');

    return (
        <Modal isOpen={true} handleClose={goToHome}>
            <Message
                title="Sorry, something went wrong"
                body="I blame gremlins. Definitely gremlins. With their weird night time talk shows and their unpredictable behavior veering between campy horror and slapstick comedy. Gremlins!"
                onConfirm={goToHome}
                confirmButtonText="Go home"
            >
                <br />
                Something wrong? We are here to help.
                <a href="mailto:support@urnowhere.com">support@urnowhere.com</a>
            </Message>
        </Modal>
    );
};

const AUTO_FORWARD_TIMEOUT = 5000;

export const WelecomeWorldModal = ({ space, notAuthorizedMessage, addtionalParams }: { space?: ApiSpace, notAuthorizedMessage: string, addtionalParams: { [key: string]: any } }) => {
    const [submitting, setSubmitting] = useState(false);
    const history = useHistory();
    const goToStations = async () => {
        history.push(await stationRedirectRoute(space?.stationId));
    }

    const ensureWWAccess = async () => {
        return await postApi('/station/acceptInvite', { inviteCode:  addtionalParams.welcomeWorldInviteCode}).then(() => true).catch((e: Error) => {
            console.log("Could not accept invite to welcome world with error: ", e);
            return false;
        });
    };

    const goToWelcomeWorld = async () => {
        await ensureWWAccess().catch(() => {
            //nothing to do here...
        });
        document.location.href = `/space/${addtionalParams.welcomeWorldSpaceId}/lobby?targetSpaceId=${space?.id?? 'no-space-id-found'}`;
    }

    const autoEnterWelcomeWorld = setTimeout(() => {
        if(!submitting && addtionalParams?.welcomeWorldSpaceId) {
          setSubmitting(true);
          goToWelcomeWorld();
        }
    }, AUTO_FORWARD_TIMEOUT);

    const skipWelcomeWorld = async () => {
        clearTimeout(autoEnterWelcomeWorld);
        await putApi('/user/me/skipWelcome', {}).then(() => {
            document.location.href = document.location.href;
        }).catch((e: Error) => {
            console.log("Could not skip welcome with error: ", e);
            alert('Could not skip welcome');
        })
    }

    return (
        <WelcomeMessageContainer>
            <WelcomeContent>
                <WelcomeMessageCell>
                    <WelcomeMessage>
                        Learn to navigate in 60 seconds!
                    </WelcomeMessage>
                </WelcomeMessageCell>
                <WelcomeButtonCell>
                    <WelcomeButtonContainer>
                        <WelcomeStart>
                            <TimerBackground />
                            <TimerWrapper>
                                <TimerProgress />
                            </TimerWrapper>
                            <StartButtonWrapper>
                                <WelcomeStartButton onClick={goToWelcomeWorld}>Start Tutorial</WelcomeStartButton>
                            </StartButtonWrapper>
                        </WelcomeStart>
                        <WelcomeSkip>
                            <WelcomeSkipButton onClick={skipWelcomeWorld}>Skip</WelcomeSkipButton>
                        </WelcomeSkip>
                    </WelcomeButtonContainer>
                </WelcomeButtonCell>
            </WelcomeContent>
        </WelcomeMessageContainer>
    );
};

const WelcomeMessageContainer = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-image: url(${WelcomeBG});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left center;
    @media (max-width: 950px){
        background-position: center center;
    }
`;

const WelcomeContent = styled.div`
    @media (min-width: 950px){
        display: flex;
        flex-direction: column;
        width: 650px;
    }
    height: 100%;
`;

const WelcomeMessageCell = styled.div`
    position: relative;
    height: 50%;
    width: 580px;
    padding-left: 120px;
    @media (max-width: 950px) {
        padding-left: 30px;
        width: 290px;
        @media (orientation: landscape){
            width: 610px;
        }
    }
`;

const WelcomeButtonCell = styled.div`
    position: relative;
    height: 50%;
    padding-left: 120px;
    padding-top: 70px;
    @media (max-width: 950px) {
        padding-left: 30px;
        padding-top: 15px;
    }
`;

const WelcomeMessage = styled.div`
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.5px;
    position: absolute;
    bottom: 0px;
    color: var(--white);
    font-size: 80px;
    @media (max-width: 950px) {
        @media (orientation: landscape){
            font-size: 65px;
        }
        @media (orientation: portrait){
            font-size: 45px;
        }
    }
`;

const LargeTransparentButton = styled.button`
    font-family: all-round-gothic,sans-serif;
    text-transform: uppercase;
    white-space: nowrap;
    display: inline-block;
    cursor: pointer;
    -webkit-transition: all 0.5s cubic-bezier(0.673,0.003,0.509,0.997);
    transition: all 0.5s cubic-bezier(0.673,0.003,0.509,0.997);
    border-radius: 30px;
    box-shadow: 0 0 10px 0 var(--shadow-black);
    background-color: transparent;
    padding: 10px 30px;
    border: 2px solid white;
    font-size: 20px;
    font-weight: bold;
    -webkit-letter-spacing: 1.4px;
    -moz-letter-spacing: 1.4px;
    -ms-letter-spacing: 1.4px;
    letter-spacing: 1.4px;
    text-align: center;
    cursor: pointer;
`;

const WelcomeButtonContainer = styled.div`
    display: flex;
    align-items: flex-start;
    @media (max-width: 950px) {
        @media (orientation: portrait) {
            display: block;
        }
    }
    height: 50px;
`;

const WelcomeStart = styled.div`
    position:relative;
    width: 250px;
    margin-right: 30px;
    height: 44px;
    @media (max-width: 950px) {
        @media (orientation: portrait) {
            height: 60px;
        }
    }
`;

const TimerWrapper = styled.div`
    position: absolute;
    top: 0px;
    width: 241px;
    height: 44px;
    border-radius: 30px;
    overflow: hidden;
`;

const TimerBackground = styled.div`
    position: absolute;
    top: 0px;
    width: 241px;
    height: 44px;
    border-radius: 30px;
    overflow: hidden;
    background-color: var(--off-white);
`;

const StartButtonWrapper = styled.div`
    position: absolute;
    z-index: 10;
    width: 241px;
`;

const WelcomeStartButton = styled(LargeTransparentButton)`
    color: var(--steel-blue);
    border: none;
    height: 44px;
    width: 241px;
`;

const ProgressTrans =  keyframes`
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
`;

const TimerProgress = styled.div`
    position: absolute;
    top: 0px;
    background-color: var(--tennis-green);
    transition: width ${AUTO_FORWARD_TIMEOUT}ms linear;
    width: 0%;
    height: 44px;
    animation: ${ProgressTrans} 5000ms linear;
    animation-fill-mode: forwards;
`;

const WelcomeSkip = styled.div`
    position: relative;
    width: 250px;
    height: 40px;
`;

const WelcomeSkipButton = styled(LargeTransparentButton)`
    color: var(--off-white);
`;


export const NotAuthorizedModal = ({ space, notAuthorizedMessage }: { space?: ApiSpace, notAuthorizedMessage: string}) => {
    const history = useHistory();
    const goToStations = async () => {
        history.push(await stationRedirectRoute(space?.stationId));
    }

    return (
        <Modal isOpen={true} handleClose={goToStations}>
            <Message
                title="Hmm, doesn’t look like you have access to this space."
                body={notAuthorizedMessage}
                onConfirm={() => goToStations()}
                confirmButtonText="Back to Station(s)"
            />
        </Modal>
    );
};

export const NoUnverifiedEmailModal = ({ space, notAuthorizedMessage }: { space?: ApiSpace, notAuthorizedMessage: string }) => {
    const history = useHistory();
    const goToStations = async () => {
        history.push(await stationRedirectRoute(space?.stationId));
    }

    const gotToVerifyEmail = async () => {
        history.push('/verify-email');
    };

    return (
        <Modal isOpen={true} handleClose={goToStations}>
            <Message title={notAuthorizedMessage} body="For the saftey of people within this Space, the host asks everyone to verify their email address to continue">
                <MessageButtonContainer>
                    <SmallButton onClick={gotToVerifyEmail}>Verify Email</SmallButton>
                </MessageButtonContainer>
            </Message>
        </Modal>
    );
};

export const NoAnonAllowedModal = ({ space, notAuthorizedMessage }: { space?: ApiSpace, notAuthorizedMessage: string }) => {
    const history = useHistory();
    const goToStations = async () => {
        history.push(await stationRedirectRoute(space?.stationId));
    }

    const gotToCreateAccount = async () => {
        history.push('/sign-up');
    };

    const goToLogin = async () => {
        history.push('/log-in');
    }

    return (
        <Modal isOpen={true} handleClose={goToStations}>
            <Message title={notAuthorizedMessage} body="For the saftey of people within this Space, the host asks everyone to log-in or create an account to continue">
                <MessageButtonContainer>
                    <SmallButton onClick={gotToCreateAccount}>Create an account</SmallButton>
                    <SmallButton onClick={goToLogin}>Login</SmallButton>
                </MessageButtonContainer>
            </Message>
        </Modal>
    );
};

export const NewUserAccessDeniedModal = ({ space, notAuthorizedMessage }: { space?: ApiSpace, notAuthorizedMessage: string }) => {
    const history = useHistory();
    const goToStations = async () => {
        history.push(await stationRedirectRoute(space?.stationId));
    }

    return (
        <Modal isOpen={true} handleClose={goToStations}>
            <Message title={notAuthorizedMessage} body="">
                <MessageButtonContainer>
                    <SmallButton onClick={goToStations}>Find Another Space</SmallButton>
                </MessageButtonContainer>
            </Message>
        </Modal>
    );
};

export const SpaceGateAuthModal = ({ space, notAuthorizedMessage }: { space?: ApiSpace, notAuthorizedMessage: string }) => {
    const [submitting, setSubmitting] = useState(false);
    const [missingAssetsMsg, setMissingAssetsMsg] = useState('');
    const [hidden, hiddenSetter] = useState(false);
    const [connectError, connectErrorSetter] = useState('');
    const history = useHistory();
    const goToStations = async () => {
        history.push(await stationRedirectRoute(space?.stationId));
    }

    const gateAccess = space!.gatedAccess!;
    const gateType = gateAccess!.type;
    const gatePolicy = gateAccess!.policy;


    const skipSpotifyFollow  = async (res: any) => {
        setSubmitting(true);
        try {
            await putApi(`/space/${space!.id}/allow`, {}).then(() => {
                window.location.reload();
            });
        } catch (e) {
            console.log("Couldn't grant space access: ", e);
            setMissingAssetsMsg("Couldn't grant space access.");
        }
        setSubmitting(false);
    };
    const onSpotifySuccess = async (res: any) => {
        setSubmitting(false);
        //console.log('callback success got: ', res);
        const gateToken = res.access_token;
        try {
            await putApi(`/space/${space!.id}/allow`, {
                    gateToken,
            }).then(() => {
                window.location.reload();
            });
        } catch (e) {
            console.log("Couldn't confirm Spotify follow: ", e);
            setMissingAssetsMsg("You do not appear to be following the required asset");
            setSubmitting(false);
        }

    };

    const onSpotifyFailure = (res: any) => {
        setSubmitting(false);
        console.log('callback failure got: ', res);
        setMissingAssetsMsg("Sorry we could not authorize your Spotify account.");
    }

    const onSpotifySubmitting = () => {
        setSubmitting(true);
    }


    const connectWallet = async () => {
        setSubmitting(true);
        hiddenSetter(true);
        try {
            const litNodeClient = new LitJsSdk.LitNodeClient();
            await litNodeClient.connect();
            const gatedAccess = space!.gatedAccess!;
            const chain = gatedAccess.policy[0]!.chain;
            const accessControlConditions = gatedAccess.policy;
            const resourceId = gatedAccess.resource;
            const authSig = await LitJsSdk.checkAndSignAuthMessage({chain: chain});

            const sBody = {
              unifiedAccessControlConditions: accessControlConditions,
              chain,
              authSig,
              resourceId,
              permanant: false
            };

            console.log("sBody: ", sBody);

            /*const sBody = {
              accessControlConditions,
              chain,
              authSig,
              resourceId,
            };*/

            const jwt = await litNodeClient.getSignedToken(sBody);
            hiddenSetter(false);
            await putApi(`/space/${space!.id}/allow`, {
                gateToken: jwt,
            }).then(() => {
                window.location.reload();
            });
        } catch (e) {
            hiddenSetter(false);
            console.log("Couldn't confirm asset ownership: ", e);
            setMissingAssetsMsg("You do not appear to hold the required asset or asset amount.");
            setSubmitting(false);
        }
    }

    const generateMessageBody = () => {
        if (missingAssetsMsg === '') return notAuthorizedMessage;
        return (
            <>
                {notAuthorizedMessage} <br/>
                {missingAssetsMsg}
            </>
        );
    }

    const handleConnectWallet = () => {
        // hiddenSetter(true);
        connectWallet();
        // hiddenSetter(false);
    }

    const capitalizeFirstLetter = (s: string) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }


    const modalMsg = () => {
        if(gateType === 'CRYPTO_ASSETS') return `${space?.title ?? 'This space'} requires that you are a holder of specific crypto assets`;
        if(gateType === 'LIST_ASSETS') return 'Access restricted';
        else {
            const policyType = gatePolicy[0]!.type;
            const action = policyType.split(",")[1];
            const assetType = policyType.split(",")[0];
            return `By connecting your Spotify you will also ${capitalizeFirstLetter(action)} this ${capitalizeFirstLetter(assetType)}, if you did not already.`
        }
    }

    const getMsgFor = (gateType: string) => {
        switch(gateType) {
            case 'MUSIC_ASSETS':
                return generateMessageBody();
                break;
            default:
                return modalMsg();
        }
    }

    const getMsgBodyFor = (gateType: string) => {
        switch(gateType) {
            case 'MUSIC_ASSETS':
                return modalMsg();
                break;
            case 'LIST_ASSETS':
                return gateAccess?.displayMessage?? 'Request access from the space owner';
                break;
            default:
                return generateMessageBody();
        }
    }

    const getMusicGateButtonText = () => {
        const policyType = gatePolicy[0]!.type;
        const action = policyType.split(",")[1];
        return `Connect & ${capitalizeFirstLetter(action)}`
    }

    const spotifyScopes = 'user-library-modify user-follow-modify user-follow-read playlist-modify-public'


    return (
        <Modal isOpen={true} handleClose={goToStations} hidden={hidden}>
            <Message title={`${getMsgFor(gateType)}`} body={getMsgBodyFor(gateType)}>
                <MessageButtonContainer>
                    {gateType === 'CRYPTO_ASSETS' && <SmallButton onClick={handleConnectWallet} disabled={submitting}>Connect Wallet</SmallButton> }
                    {gateType === 'MUSIC_ASSETS' && <SpotifyAuthButton scope={spotifyScopes} onSubmitting={onSpotifySubmitting} onSuccess={onSpotifySuccess} onFailure={onSpotifyFailure} disabled={submitting}>{getMusicGateButtonText()}</SpotifyAuthButton> }
                    {gateType === 'CRYPTO_ASSETS' &&
                        <SmallButton onClick={goToStations}>Find Another Space</SmallButton>
                    }
                    {gateType === 'MUSIC_ASSETS' &&
                        <a href="#" onClick={skipSpotifyFollow}>Continue Without Follow</a>
                    }
                    {gateType === 'LIST_ASSETS' &&
                        <SmallButton onClick={goToStations}>Find Another Space</SmallButton>
                    }
                </MessageButtonContainer>
            </Message>
        </Modal>
    );
};

export const SpaceClosedModal = ({ space }: { space?: ApiSpace }) => {
    const history = useHistory();
    const goToStations = async () => {
        history.push(await stationRedirectRoute(space?.stationId));
    }

    return (
        <Modal isOpen={true} handleClose={goToStations}>
            <Message
                title="This Space is closed."
                body="Must be opened by a host to enter"
                onConfirm={goToStations}
                confirmButtonText="Station Page"
            />
        </Modal>
    );
};

export const AtCapacitySpaceOverFlowModal = ({ space }: { space?: ApiSpace }) => {
    const history = useHistory();
    const goToStations = async () => {
        history.push(await stationRedirectRoute(space?.stationId));
    };

    const overflowSpaceId = space?.overflowSpaceId;
    const isShowingButtons = overflowSpaceId? false: true;

    const msg = (overflowSpaceId)?
        `Sorry ${space?.title} is at capacity, sending you to the overflow space`:
        `Sorry. ${space?.title} is at capacity`;


    const body = (overflowSpaceId)?
        "You will be redirected momentarily":
        "Do you wanna...";


    const geToOverflowSpace = (osid?: string) => {
        document.location.href = `/space/${osid?? ''}/lobby`;
    };

    if(overflowSpaceId) {
        console.log("overflowSpaceId: ", overflowSpaceId);
        setTimeout(() => {
            geToOverflowSpace(overflowSpaceId);
        }, 5000);
    }

    return (
        <Modal isOpen={true} handleClose={goToStations}>
            <Message title={msg} body={body}>
                <MessageButtonContainer>
                    {isShowingButtons && <SmallButton onClick={() => window.location.reload()}>Try Again</SmallButton>}
                    {isShowingButtons && <SmallButton onClick={goToStations}>Find Another Space</SmallButton>}
                </MessageButtonContainer>
            </Message>
        </Modal>
    );
};

export const AtCapacitySpectateOverFlow  = ({ space }: { space?: ApiSpace }) => {
  const history = useHistory();

  const goToStations = async () => {
    history.push(await stationRedirectRoute(space?.stationId));
  };

  const goToSpectate = async () => {
   document.location.href = `/space/${space?.id?? ''}/lobby?isForcedSpectate=true`;
  };

  const msg = `Sorry ${space?.title} is at capacity, joining as a Spectator`;
  const body = "You can chat and view the space, but will not be able to move or speak to others. You will be redirected momentarily.";

  setTimeout(() => {
      goToSpectate();
  }, 5000);

   return (
        <Modal isOpen={true} handleClose={goToStations}>
            <Message title={msg} body={body}>
                <MessageButtonContainer>
                    <SmallButton onClick={() => window.location.reload()}>Try Again</SmallButton>
                    <SmallButton onClick={goToStations}>Find Another Space</SmallButton>
                </MessageButtonContainer>
            </Message>
        </Modal>
    );
};

export const AtCapacityStationOverviewOverFlow = ({ space }: { space?: ApiSpace }) => {
    const history = useHistory();
    const goToStations = async () => {
        history.push(await stationRedirectRoute(space?.stationId));
    };

    const overflowStationId = space?.overflowSpaceId?.split('_station:')[1]?? space!.stationId!;

    const msg = `Space is full`;


    const body = `${space?.title} is currently at capacity, other options:`;

    //{Stream Viewer} {Find Another Space} {Try Again}

    const goToStreamViewer = (sId?: string) => {
        document.location.href = `/space/${sId?? ''}/lite`;
    };

    return (
        <Modal isOpen={true} handleClose={goToStations}>
            <Message title={msg} body={body}>
                <MessageButtonContainer>
                    {<SmallButton onClick={() => goToStreamViewer(space?.id)}>Stream Viewer</SmallButton>}
                    {<SmallButton onClick={goToStations}>Find Another Space</SmallButton>}
                    {<SmallButton onClick={() => window.location.reload()}>Try Again</SmallButton>}
                </MessageButtonContainer>
            </Message>
        </Modal>
    );
};

export const AtCapacityModal = ({ space }: { space?: ApiSpace }) => {
    const capType = (() => {
      const of = (space?.overflowSpaceId?? '');
      if(of.indexOf('_station:') === 0)  return 'STATION_OVERFLOW';
      if(of.indexOf('_spectate:') === 0) return 'SPECTATE_OVERFLOW';
      return 'SPACE_OVERFLOW';
    })();

    switch(capType) {
      case 'SPACE_OVERFLOW':
        return AtCapacitySpaceOverFlowModal({ space });
        break;
      case 'STATION_OVERFLOW':
        return AtCapacityStationOverviewOverFlow({ space });
        break;
      case 'SPECTATE_OVERFLOW':
        return AtCapacitySpectateOverFlow({ space });
        break;
      default:
        return AtCapacitySpaceOverFlowModal({ space });
    }
};

export const ActiveConfirmModal: React.FC<{ spaceId: string; enterSpace: () => void }> = ({ spaceId, enterSpace }) => {
    const history = useHistory();
    function enterAndOpen() {
        postApi(`/space/${spaceId}/update`, {
            isActive: true,
            attendance: 0,
            hostStartTime: new Date()
        });
        enterSpace();
    }

    const backToSpace = () => history.push(`/space/${spaceId}`);

    return (
        <Modal isOpen={true} handleClose={backToSpace}>
            <Message
                title="Space closed."
                body="Let's get the party started! Set space to active and allow others to join?"
                onConfirm={enterAndOpen}
                confirmButtonText="OPEN & ENTER"
                onCancel={backToSpace}
            />
        </Modal>
    );
};
