import { envAsBool } from './envLoader';

class TranslationHelper {

    public defaultShortLang = 'en';
    public defaultLang = 'en-US';

    private _baseMap: { [key: string]: string } = {
      'pt-BR': 'br',
      'pt': 'br',
      'br': 'br',
    };


    public get langMap(): { [key: string]: string } {
      return !envAsBool('ENABLE_FRENCH_TRANSLATE')?
        this._baseMap: {
          ...this._baseMap,
          fr: 'fr',
          'fr-CA': 'fr'
        };
    };

    public translate(lang?: string) {
        console.log('langMap: ', this.langMap);

        try {
          const l = lang?? this.localLang;
          const sl = this.langMap[l]?? this.defaultShortLang;
          // @ts-ignore
          Weglot.switchTo(sl);
          return `Translated to ${sl}`;
        } catch(e) {
          return `Translation failed with error ${e.message}`;
        }
     }

    public get localLang(): string {
        if(!envAsBool("ENABLE_AUTO_TRANSLATE")) return this.defaultLang;

        try {
          return navigator.language;
        } catch(e) {
          console.log('Could not get browsers default language with error: ', e);
          return this.defaultLang;
        }
    }
}

export const translationHelper = new TranslationHelper();
