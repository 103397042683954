import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { putApi } from '../api/api';
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

const AcceptMeetInvite: React.FC = () => {
  const { meetInviteCode } = useParams() as any;
  const history = useHistory();
  const [error, setError] = useState(false);

  useEffect(() => {
    (async () => {
      const response = await putApi(`/meet/invite/${meetInviteCode}`).catch(() => setError(true));
        if (!response || !response.ok) {
          setError(true);
          return;
        }
         const { success, meetInvite } = await response.json();

         const isSpectateInvite = ((meetInvite?.roleGrant?? 'MEMBER') === 'SPECTATOR');

         if(!success) {
           setError(true);
           return;
         }

         history.push(`/space/${meetInvite.spaceId}/lobby${isSpectateInvite? '?isForcedSpectate=true': ''}`);
    })();
  }, [meetInviteCode]);

  return <MeetInviteDiv>
    <h3>{error? 'Meet invite code invalid': ''}</h3>
  </MeetInviteDiv>;
};

const MeetInviteDiv = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: red;
`;

export default AcceptMeetInvite;
