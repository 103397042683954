import { ApiStation, ApiUser, ApiSpace, StationPermission, StationRoleType } from '../api/ApiTypes';
import { canAdmin } from './users';

export const isStationManager = (station?: ApiStation, user?: ApiUser) => {
  const role = station?.role?? 'NONE';
  const admin = canAdmin(user!);

  const isStationManager = (station && ['MANAGER', 'OWNER'].includes(role)) || admin;
  return isStationManager;
}

export const isStationOwner = (role: StationRoleType) => ['OWNER'].includes(role);

export const hasOwnerRole = (station?: ApiStation, user?: ApiUser) => {
  const role = station?.role?? 'OUTCAST';
  const admin = canAdmin(user!);

  return isStationOwner(role) || admin;
};

export const isSpaceHostInStation = (station?: ApiStation) =>
    station?.hostedSpaceIds && station?.hostedSpaceIds.length > 0;

export const stationHas = (station: ApiStation, perm: string) => {
    return station?.permissions?.find((p: StationPermission) => {
        return p.name === perm && p.allowed === true;
    })? true: false;
};

export const canLiveStream = (station: ApiStation) => {
    return stationHas(station, 'canLiveStream');
};

export const canEmbed = (station: ApiStation) => {
  return stationHas(station, 'canEmbed');
};

export const canAccessAdvancedEmbedOptions = (station: ApiStation) => {
  return stationHas(station, 'canAccessAdvancedEmbedOptions');
}

export const canHideBranding = (station: ApiStation) => {
  return stationHas(station, 'canHideBranding');
}

export const canViewAttendeeContactInfo = (station: ApiStation) => {
  return stationHas(station, 'canViewAttendeeContactInfo');
};

export const canAccessAdvancedSettings = (station: ApiStation) => {
  return stationHas(station, 'canAccessAdvancedSettings');
}

export const canShowStreamViewerButton = (station: ApiStation, space: ApiSpace) => {
  return canLiveStream(station) && !space?.isStreamViewerButtonDisabled;
}

export const canSpacesBeOpened = (station: ApiStation) => {
  return stationHas(station, 'canSpacesBeOpened');
}

export const hasSpaceCapacityLimit = (station: ApiStation) => {
  const perm = station?.permissions?.find((p: StationPermission) => {
    return p.name === 'hasSpaceCapacityLimit' && p.allowed === true;
  });

  return perm?.data?.maxAllowed?? 0;
}

