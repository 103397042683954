import * as React from 'react';
import { detect as browser } from 'detect-browser';
import { Modal } from '../layouts/Modal';
import { MediumButton } from '../inputs/Button';
import Cookies from 'js-cookie';
import chromeLogo from '../../images/chrome_icon.png';
import pcImage from '../../images/pc@3x.png';
import headphonesIcon from '../../images/i-headphones-30.png';
import styled from 'styled-components/macro';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Just cribbing from:
// https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
const isMobile = () => {
    let check = false;
    (function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(
                a.substr(0, 4)
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor);
    return check;
};


const isIOS = () => {
        return (/iPad|iPhone|iPod|iPhone Simulator|iPad Simulator|iPad Simulator/.test(navigator.platform)) ||
        (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel|Macintosh/.test(navigator.platform)) ||
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
};

const ChromeOnlyModal: React.FC = () => {
    const [modalClosed, setModalClosed] = useState(true);
    const { pathname } = useLocation() as any;
    const isDesktop = React.useMemo(() => !isMobile(), []);
    const isIos = React.useMemo(() => isIOS(), []);

    useEffect(() => {
        const modalSeenRecently = !!Cookies.get('ChromeOnlyBypass');
        const browserName = browser()?.name;
        const isValid = ['chrome', 'firefox', 'edge'].includes(browserName?? 'unkown') || !isDesktop;
        const isNotLobby = !pathname.includes('lobby');
        setModalClosed(isValid || (modalSeenRecently && isNotLobby));
    }, [pathname, isDesktop]);

    const agreeToBadExperience = () => {
        const twoHoursFromNow = 2 / 24;
        // in cookies-js lib, expires option in Cookies set is interpreted in days, 2/24
        // ergo two hours from current time == 2/24
        // see https://github.com/js-cookie/js-cookie/wiki/Frequently-Asked-Questions#how-to-make-the-cookie-expire-in-less-than-a-day
        Cookies.set('ChromeOnlyBypass', 'skip nag screen for 2 hrs', { expires: twoHoursFromNow });
        setModalClosed(true);
    };

    return (
        <Modal isOpen={!modalClosed}>
            <InnerContainer>
                {/* {isDesktop ? <BadBrowserContent /> : <MobileContent />} */}
                <BadBrowserContent />
                {/* {isIos ? <IOSContent /> : null} */}
                <MediumButton onClick={agreeToBadExperience}>Continue</MediumButton>
            </InnerContainer>
        </Modal>
    );
};

const BadBrowserContent = () => (
    <>
        <TopContainer>
            <img height="55px" style={{ marginRight: '10px' }} src={chromeLogo} alt="Chrome Logo" />
            <h2> Please switch to Chrome</h2>
        </TopContainer>
        <div>
            NOWHERE is optimized for Google Chrome, switch browsers for the best experience.
        </div>
    </>
);

const MobileContent = () => (
    <>
        <TopContainer>
            <img height="45px" style={{ marginRight: '10px', marginTop: '7.5px' }} src={pcImage} alt="Mobile Phone" />
            <h2> Use a computer for the best experience.</h2>
        </TopContainer>
        <div>
        While we have basic mobile support, we highly recommend joining on a computer with the Chrome browser.
        </div>

    </>
);

const IOSContent = () => (
    <>
        <TopContainerIOS>
            <img height="30px" style={{ marginRight: '10px' }} src={headphonesIcon} alt="Headphones" />
            <h4>Put on your headphones!</h4>
        </TopContainerIOS>
        <div>
          Some iOS devices may cause echo for others and experience audio issues if you do not wear headphones.
        </div>

    </>
);

const TopContainer = styled.div`
    display: flex;
    align-items: top;
`;

const TopContainerIOS = styled.div`
    display: flex;
    align-items: center;
`;
const InnerContainer = styled.div`
    max-width: 80%;

    @media all and (min-width: 736px) {
        max-width: 600px;
    }
    > * {
        margin: 20px 0;
    }
`;

export default ChromeOnlyModal;
